import sleep from '@/helpers/sleep';
import { permissions as perms } from '@/rbac';
import DevLogger from '@/helpers/dev-logger';
import AuthUser from '~/helpers/auth-user';
import jwtDecode from 'jwt-decode';

/*
Also skip "landing" page "/" which has it's own redirect mechanism which
checks whether the user is a "full system user" or not and redirects
the user accordingly
 */
const SKIP_PATHS = ['/login', '/logout', '/legal', '/reset-password'];

/**
 * Middleware that grants access to pages that use the metadata "auth" property on routes. User permissions
 * are checked against required permissions to access the specific page. If user meets the required permissions,
 * the user is granted access to the page. If not, the user is redirected to a 403 error page.
 *
 * @param error
 * @param store
 * @param app
 * @param route
 * @param redirect
 * @param $fire
 * @return {*}
 */
export default async ({ error, store, app, route, redirect, $fire }) => {
    // do not run middleware if suppress flag is found
    if (app.router.suppressMiddleware) {
        DevLogger.log("Suppressed 'grant-access' middleware");

        return;
    }

    const authUser = AuthUser.loadFromStorage();

    DevLogger.log('-----------------------------------------------------');
    DevLogger.log(`[ ${route.path} ]`);
    DevLogger.log('Auth user:');
    DevLogger.log(authUser);
    DevLogger.log('-----------------------------------------------------');

    // if user is logged in and jumps to login page, redirect to home directly
    if (authUser.isLoggedIn() && route.path === '/login') {
        redirect('/');
    }

    // skip grant access checks for defined routes
    if (SKIP_PATHS.includes(route.path)) {
        DevLogger.log(`No grant access check required for route path ${route.path}.`);

        return;
    }
    //  If user is not logged in and tries to access a page that requires permissions,
    //  keep and forward destination "fullPath" to redirect the user to the requested destination
    // after a successful login
    if (!authUser.isLoggedIn()) redirect(`/logout?destination=${encodeURIComponent(route.fullPath)}`);

    // check for app access is always required
    if (!authUser.hasPermission(perms.MONITORING_APP_ACCESS)) {
        DevLogger.log('User does not have the required permissions to access this application.');
        // IMPORTANT user is authenticated but has no permissions => logout instead of redirect to prevent infinite loop
        redirect('/logout?deferNotify=appAccessDenied');
    }

    // enforce token refresh if token is expired
    const token = authUser.getToken();
    if (token) {
        // exp is in seconds
        const { exp } = jwtDecode(token);
        const expMillis = exp * 1000;
        const now = Date.now();
        const refreshOffset = 300 * 1000;
        const timeToRefresh = parseInt(expMillis - (now + refreshOffset));

        if (timeToRefresh <= 0) {
            try {
                DevLogger.log('Refreshing expired Firebase ID token');
                // IMPORTANT await get auth instance -> do not chain-call any method!
                while ($fire.auth.currentUser == null) {
                    console.log($fire.auth.currentUser);
                    await sleep(1000);
                    // error out if waiting time is too long
                    if (Date.now() - now > 30000) {
                        throw new Error('Timeout while waiting for Firebase auth instance');
                    }
                }
                const currentUser = $fire.auth.currentUser;
                const token = await currentUser.getIdToken(true);
                const authUser = AuthUser.loadFromStorage();
                authUser.updateToken(token);
                authUser.persistToStorage();
                DevLogger.log('Successfully refreshed expired Firebase ID token');
            } catch (e) {
                DevLogger.error(`Failed to refresh Firebase ID token. ${e.message}`);
            }
        }
    }

    // Each page can define a list of roles and/or permissions that are allowed to access the page
    // via route's "meta" property. The value of this property is extracted below.
    let metaAuth = route.meta.map((meta) => meta.auth || false);
    metaAuth = metaAuth && metaAuth.length === 1 ? metaAuth[0] : false;

    let checksPassed = 0;
    let requiredChecks = 0;

    // set check if role grants are required to access the page
    const requiredRoleGrant = !metaAuth || !metaAuth.roles ? false : metaAuth.roles.length > 0;
    requiredChecks += requiredRoleGrant ? 1 : 0;

    // set check if permission grants are required to access the page
    const requiredPermissionGrant = !metaAuth || !metaAuth.permissions ? false : metaAuth.permissions.length > 0;
    requiredChecks += requiredPermissionGrant ? 1 : 0;

    if (requiredChecks === 0) {
        DevLogger.log('Access granted. No checks required.');

        return;
    }

    // make sure that the current user has the required role defined in route's meta property
    if (requiredRoleGrant) {
        DevLogger.log(`Required role grants: ${metaAuth.roles.join(',')}`);
        DevLogger.log(`User roles: ${authUser.roles}`);
        // add passed checked
        const checksRolePassed = authUser.hasRole(metaAuth.roles) ? 1 : 0;
        checksPassed += checksRolePassed;

        DevLogger.log(`Role checks required, passed: ${checksRolePassed}`);
    }

    // make sure that the current user has the required permissions defined in route's meta property
    if (requiredPermissionGrant) {
        DevLogger.log(`Required permissions grants: ${metaAuth.permissions.join(',')}`);
        DevLogger.log(`User permissions: ${authUser.permissions}`);
        // ALL permissions are required
        const checksPermissionPassed = authUser.hasPermission(metaAuth.permissions) ? 1 : 0;
        checksPassed += checksPermissionPassed;

        DevLogger.log(`Permissions checks required, passed: ${checksPermissionPassed}`);
    }

    if (checksPassed < requiredChecks) {
        DevLogger.log(`User is not granted access to resource. Checks required: ${requiredChecks} |  Checks passed: ${checksPassed}`);
        const message = encodeURIComponent("You don't have the required permissions to access this page");

        return error({ statusCode: 403, statusText: message });
    }

    DevLogger.log(`User granted access. Checks required: ${requiredChecks} |  Checks passed: ${checksPassed}`);
};
