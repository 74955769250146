<script>
import UserPrefs from '@/helpers/user-prefs';
import rbacMeta from '~/mixins/rbac-meta';

export default {
    name: 'BaseLayout',
    mixins: [rbacMeta],
    data() {
        return {
            ticked: false,
        };
    },
    async created() {
        this.$store.commit('clearAppTicker');

        // load user from local storage
        let userPrefs = UserPrefs.loadFromLocalStorage();
        if (!userPrefs) {
            // no user prefs set in storage, set default ones and persist to local storage
            userPrefs = new UserPrefs();
            // if no user prefs is set, adhere to browser's preferred color scheme
            const { matches } = window.matchMedia('(prefers-color-scheme: dark)');
            userPrefs.darkMode = matches;
            userPrefs.persistToLocalStorage();
        }
        // update current app state with persistent user prefs
        this.$store.commit('setUserPreferences', userPrefs);
        setTimeout(() => (this.$vuetify.theme.dark = this.$store.state.darkMode), 0);

        // only start app ticker if user is logged in
        if (this.authUser.isLoggedIn()) {
            this.ticked = true;
            this.dispatchBackgroundTasks();
            this.ticked = false;

            // add logic that needs to be run periodically here
            this.$store.commit(
                'setAppTicker',
                setInterval(async () => {
                    // always wait until last request has finished
                    if (this.ticked) {
                        return;
                    }
                    this.ticked = true;
                    this.dispatchBackgroundTasks();
                    this.ticked = false;
                }, 30000)
            );
        }
    },
    destroyed() {
        // IMPORTANT clear all intervals
        this.$store.commit('clearAppTicker');
    },
    methods: {
        dispatchBackgroundTasks() {
            // IMPORTANT make sure all requests run without "await" and with fast timeouts to not block other requests
            this.$store.dispatch('refreshFirebaseToken').catch((e) => {});
            this.$store.dispatch('loadNotifications').catch((e) => {});
            this.$store.dispatch('loadSystemState').catch((e) => {});
            this.$store.dispatch('systemMessage/list/load').catch((e) => {});
            this.$store.dispatch('loadLatestAppVersion').catch((e) => {});
        },
    },
};
</script>

<style scoped></style>
