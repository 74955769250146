import { deferNotifyHandler } from '@/helpers/defer-notify-handler';

let handler;

export default ({ app, store }) => {
    handler = deferNotifyHandler(store.dispatch);

    app.router.beforeEach(async (to, from, next) => {
        await handler(to);

        next();
    });
};
