<template>
    <v-app>
        <navbar />
        <main-navigation />
        <notification />
        <v-main>
            <maintenance-mode-alert />
            <system-messages />
            <nuxt />
        </v-main>
        <page-footer />
        <scroll-to-top-button />
    </v-app>
</template>

<script>
import Navbar from '@/components/Navbar';
import MainNavigation from '@/components/MainNavigation';
import Notification from '@/components/Notification';
import PageFooter from '@/components/PageFooter';
import ScrollToTopButton from '@/components/ScrollToTopButton';
import SystemMessages from '@/components/SystemMessages';
import MaintenanceModeAlert from '@/components/MaintenanceModeAlert';
import BaseLayout from '@/layouts/base';

export default {
    name: 'AppLayout',
    components: {
        MaintenanceModeAlert,
        SystemMessages,
        ScrollToTopButton,
        Navbar,
        MainNavigation,
        PageFooter,
        Notification,
    },
    extends: BaseLayout,
};
</script>
