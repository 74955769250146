import { initializeApp, getApps } from 'firebase/app';
import {
    connectAuthEmulator,
    onAuthStateChanged,
    browserLocalPersistence,
    sendEmailVerification,
    reauthenticateWithCredential,
    verifyBeforeUpdateEmail,
    EmailAuthProvider,
    updatePassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithCustomToken,
    signOut,
    initializeAuth,
} from 'firebase/auth';

let firebaseApp;
let auth;

export default async ({ app, store, $config }, inject) => {
    const firebaseConfig = {
        apiKey: $config.GCP_FIREBASE_API_KEY,
        authDomain: $config.GCP_FIREBASE_AUTH_DOMAIN,
        projectId: $config.GCP_PROJECT_ID,
        appId: null,
        storageBucket: null,
        messagingSenderId: null,
    };

    const apps = getApps();
    // make sure firebase app is not initialized twice (which would result in an error)
    if (apps.length) {
        firebaseApp = apps[0];
    } else {
        firebaseApp = initializeApp(firebaseConfig);
    }

    // initialize auth module
    auth = initializeAuth(firebaseApp, { persistence: browserLocalPersistence });
    // if required, use Auth emulator https://firebase.google.com/docs/emulator-suite/connect_auth#web-version-9
    if (process.env.NODE_ENV === 'development') {
        connectAuthEmulator(auth, 'http://localhost:9099');
    }
    onAuthStateChanged(auth, async (authUser) => {
        const claims = authUser ? (await authUser.getIdTokenResult(true)).claims : null;
        // dispatch required events onIdTokenChanged
        // store.commit('onIdTokenChangedMutation', { authUser, claims });
        await store.dispatch('onAuthStateChangedAction', { authUser, claims });
    });

    const fire = {
        app,
        auth,
        sendEmailVerification,
        verifyBeforeUpdateEmail,
        EmailAuthProvider,
        reauthenticateWithCredential,
        updatePassword,
        sendPasswordResetEmail,
        signInWithEmailAndPassword,
        signInWithCustomToken,
        signOut,
    };

    // inject firebase app instance into context
    inject('fire', fire);
};
