<template>
    <v-app>
        <map-navbar />
        <notification />
        <nuxt />
    </v-app>
</template>

<script>
import rbacMeta from '@/mixins/rbac-meta';
import Notification from '@/components/Notification';
import MapNavbar from '@/components/MapNavbar';
import BaseLayout from '@/layouts/base';

export default {
    name: 'Map',
    components: { MapNavbar, Notification },
    extends: BaseLayout,
    mixins: [rbacMeta],
};
</script>
