import md5 from 'md5';

const BASE_URL = 'https://www.gravatar.com/avatar';

export default class Gravatar {
    constructor(email) {
        // simple use the current time to creat a astring that is used to create random gravatar icon
        const gravastring = email || Date.now().toString();
        // create md5 hash
        const trimmed = gravastring.trim();
        const lowercase = trimmed.toLowerCase();
        this._hash = md5(lowercase);
    }

    getImageUrl() {
        return BASE_URL + '/' + this._hash + '?s=28&d=identicon';
    }
}
