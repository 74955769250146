import sleep from '@/helpers/sleep';
import axios from 'axios';
import checkTypes from 'check-types';
import jwtDecode from 'jwt-decode';
import { DateTime, Duration } from 'luxon';
import { REQUEST_POLLING_TIMEOUT } from '@/globals';
import UserPrefs from '@/helpers/user-prefs';
import AuthUser from '@/helpers/auth-user';
import DevLogger from '@/helpers/dev-logger';
import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    // holds the latest available app version if loaded from backend URL
    latestAppVersion: null,
    // holds interval that ticks and does some backend queries, e.g. fetching notifications etc
    // -> ticker is defined in base layout
    appTicker: null,
    mainNavigation: false,
    mainNavToggle: true,
    mapHomeLink: '/',
    mapHomeTitle: 'Back to list',
    notifyShow: false,
    notifyText: '',
    notifyType: 'info',
    notifyTimeout: 5000,
    // Holds the URL the user is redirected to if the "Back to list" button is clicked
    originUrl: null,
    notifications: [],
    // properties derived from the system state but with frontend specific default values set to
    // ensure that frontend is working also before initial system state load
    maintenanceMode: false,
    systemStateUpdateTimestamp: null,
    // last timestamp in ms when requested permissions
    permsLastRequested: null,
    // user preferences below are persisted to local storage and are available
    // throughout the portal web applications
    darkMode: false,
});

export const mutations = {
    clear(state) {
        state.latestAppVersion = null;
        state.appTicker = null;
        state.notifyShow = false;
        state.notifyText = null;
        state.notifyType = 'info';
        state.notifyTimeout = 5000;
        state.mapHomeLink = '/';
        state.mapHomeTitle = 'Back to list';
        state.originUrl = null;
        state.notifications = [];
        state.maintenanceMode = false;
        state.systemStateUpdateTimestamp = null;
        state.permsLastRequested = null;
        state.tokenExpiration = null;
        state.darkMode = false;
    },
    setLatestAppVersion(state, value) {
        state.latestAppVersion = valueTransform.toString(value, { nullable: true });
    },
    setAppTicker(state, value) {
        state.appTicker = valueTransform.toInteger(value);
    },
    clearAppTicker(state, value) {
        clearInterval(state.appTicker);
    },
    setMapHomeLink(state, value) {
        state.mapHomeLink = valueTransform.toString(value);
    },
    setMapHomeTitle(state, value) {
        state.mapHomeTitle = valueTransform.toString(value);
    },
    setNotifyShow(state, value) {
        state.notifyShow = valueTransform.toBoolean(value);
    },
    setNotifyText(state, value) {
        state.notifyText = valueTransform.toString(value);
    },
    setNotifyTimeout(state, value) {
        state.notifyTimeout = valueTransform.toInteger(value);
    },
    setNotifyType(state, value) {
        state.notifyType = valueTransform.toString(value);
    },
    setMainNavigation: (state, value) => {
        state.mainNavigation = valueTransform.toBoolean(value);
        state.mainNavToggle = !valueTransform.toBoolean(value);
    },
    setMainNavToggle(state, value) {
        state.mainNavToggle = valueTransform.toBoolean(value);
    },
    setOriginUrl(state, value) {
        state.originUrl = valueTransform.toString(value, { nullable: true });
    },
    setNotifications(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyObject(value);
        state.notifications = value;
    },
    setMaintenanceMode(state, value) {
        state.maintenanceMode = valueTransform.toBoolean(value);
    },
    setSystemStateUpdateTimestamp(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.systemStateUpdateTimestamp = value;
    },
    setPermsLastRequested(state, value) {
        state.permsLastRequested = valueTransform.toInteger(value);
    },
    setDarkMode(state, value) {
        value = valueTransform.toBoolean(value);
        state.darkMode = value;
        // set and persist user preferences
        const userPrefs = UserPrefs.loadFromLocalStorage();
        userPrefs.darkMode = value;
        userPrefs.persistToLocalStorage();
    },
    setUserPreferences(state, value) {
        const { darkMode } = value || {};

        state.darkMode = darkMode || false;
    },
};

export const actions = {
    async notify({ commit }, { message, type = 'info', timeout = 5000 }) {
        checkTypes.assert.nonEmptyString(message);
        checkTypes.assert.nonEmptyString(type);

        /*
        Accepts notification object:
            {
                message: 'Notification text to show',
                type: 'info'
            }
         */
        if (!message) {
            throw new Error('Notification config options must contain a message property.');
        }

        commit('setNotifyShow', true);
        commit('setNotifyText', message);
        commit('setNotifyType', type);
        commit('setNotifyTimeout', timeout);
    },
    async loadNotifications({ commit }) {
        // suppress request errors and do not show them to the user
        const notifications = await this.$axios.$get('/api/notification/notifications', {
            // IMPORTANT set to a small timeout so that requests do not block
            timeout: REQUEST_POLLING_TIMEOUT,
        });
        commit('setNotifications', notifications);
    },
    async loadSystemState({ commit }) {
        // suppress request errors and do not show them to the user
        const { state, updatedAt } = await this.$axios.$get('/api/operating/system/health', {
            // IMPORTANT set to a small timeout so that requests do not block
            timeout: REQUEST_POLLING_TIMEOUT,
        });

        commit('setMaintenanceMode', state === 'MAINTENANCE');
        commit('setSystemStateUpdateTimestamp', DateTime.fromISO(updatedAt));
    },
    async loadLatestAppVersion({ commit, dispatch }) {
        if (process.env.NODE_ENV !== 'development') {
            const data = await this.$axios.$get(`/assets/common/${this.$config.GCP_SERVICE_NAME}-version.txt`, {
                // IMPORTANT set to a small timeout so that requests do not block
                timeout: REQUEST_POLLING_TIMEOUT,
            });

            commit('setLatestAppVersion', data);

            if (data !== this.$config.APP_VERSION) {
                await dispatch('notify', { message: this.$i18n.t('new_app_version_available'), timeout: 15000 });
            }
        }
    },
    async refreshFirebaseToken({ state, getters }) {
        const refreshOffset = 300 * 1000;
        // current timestamp in milliseconds
        const now = Date.now();
        const authUser = AuthUser.loadFromStorage();
        const token = authUser.getToken();

        if (token) {
            // exp is in seconds
            const { exp } = jwtDecode(token);
            const expMillis = exp * 1000;

            const timeToExpire = parseInt(expMillis - now);
            const timeToRefresh = parseInt(expMillis - (now + refreshOffset));

            if (timeToRefresh <= 0) {
                try {
                    DevLogger.log('Refreshing expired Firebase ID token');
                    // IMPORTANT await get auth instance -> do not chain-call any method!
                    while (this.$fire.auth.currentUser == null) {
                        console.log(this.$fire.auth.currentUser);
                        await sleep(1000);
                        // error out if waiting time is too long
                        if (Date.now() - now > 30000) {
                            throw new Error('Timeout while waiting for Firebase auth instance');
                        }
                    }
                    const currentUser = this.$fire.auth.currentUser;
                    const token = await currentUser.getIdToken(true);
                    const authUser = AuthUser.loadFromStorage();
                    authUser.updateToken(token);
                    authUser.persistToStorage();
                } catch (e) {
                    DevLogger.error(`Failed to refresh Firebase ID token. ${e.message}`);
                }
            } else {
                const tte = Duration.fromMillis(timeToExpire).toFormat("mm'm' ss's'");
                const ttr = Duration.fromMillis(timeToRefresh).toFormat("mm'm' ss's'");

                DevLogger.log(`Firebase token will expire in ${tte} and will be refreshed in ${ttr}`);
            }
        }
    },
    async onAuthStateChangedAction({ state, commit }, { authUser: firebaseUser, claims }) {
        if (firebaseUser) {
            const token = await firebaseUser.getIdToken();
            // deconstruct and set mandatory fields
            const { uid, email, emailVerified, displayName } = firebaseUser;
            let roles = [];
            let permissions = [];
            let businessEntity = null;
            try {
                // at this point, internally used axios wrapper is not yet available, requires use of default axios instance
                const response = await axios.get(`/api/iam/rbac/users/${firebaseUser.uid}`, {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                });
                const { permissions: userPermissions, roles: userRoles, businessEntity: userBusinessEntityId } = response.data;

                permissions = userPermissions;
                roles = userRoles;
                // load business entity metadata from backend if user is linked
                if (userBusinessEntityId) {
                    const response = await axios.get(`/api/operating/business-entities/${userBusinessEntityId}`, {
                        params: { fields: '_id,name' },
                        headers: {
                            authorization: `Bearer ${token}`,
                        },
                    });
                    const { _id, name } = response.data;
                    businessEntity = { _id, name };
                }
            } catch (e) {
                const defaultOptions = {
                    permissions: [],
                    roles: [],
                    businessEntity: null,
                };
                // do nothing and use old values if available
                const { permissions: userPermissions, roles: userRoles } = {
                    ...defaultOptions,
                    ...(state.authUser || {}),
                };
                // use a default set with empty permissions and roles
                permissions = userPermissions;
                roles = userRoles;
            }

            // set the auth user JSON representation
            const metadata = {
                uid,
                email,
                emailVerified,
                roles,
                permissions,
                displayName,
                token,
                businessEntity,
            };
            // persist auth user to local storage to persist during page reloads
            const authUser = new AuthUser(metadata);
            authUser.persistToStorage();
        }
    },
    async logoutUser({ state, commit }) {
        await this.$fire.signOut(this.$fire.auth);
        AuthUser.logout();
    },
};
