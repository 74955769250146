import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    notifyBnkSystemErrors: false,
    notifyBnkSystemErrorsThreshold: 0,
});

export const mutations = {
    clear(state) {
        state.notifyBnkSystemErrors = false;
        state.notifyBnkSystemErrorsThreshold = 0;
    },
    setNotifyBnkSystemErrors(state, value) {
        state.notifyBnkSystemErrors = valueTransform.toBoolean(value);
    },
    setNotifyBnkSystemErrorsThreshold(state, value) {
        state.notifyBnkSystemErrorsThreshold = valueTransform.toInteger(value);
    },
};

export const actions = {
    async load({ commit }) {
        const { settings } = await this.$axios.$get('/api/iam/users/profile', { params: { fields: 'settings' } });
        const { notifications } = settings ?? {};

        commit('setNotifyBnkSystemErrors', notifications?.bnkSystemErrors?.notify);
        commit('setNotifyBnkSystemErrorsThreshold', notifications?.bnkSystemErrors?.threshold);
    },
    async edit({ state }) {
        const denormalized = {
            settings: {
                notifications: {
                    bnkSystemErrors: {
                        notify: state.notifyBnkSystemErrors,
                        threshold: state.notifyBnkSystemErrorsThreshold,
                    },
                },
            },
        };
        await this.$axios.$patch('/api/iam/users/profile', denormalized);
    },
};
