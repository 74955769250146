<script>
import Navbar from '@/components/Navbar';

export default {
    name: 'MapNavbar',
    extends: Navbar,
    methods: {
        openSidebar() {
            this.$store.commit('map/map/setSidebar', true);
        },
    },
};
</script>
