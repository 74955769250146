import { render, staticRenderFns } from "./AppSwitcher.vue?vue&type=template&id=989746d0&scoped=true&v-show=%24vuetify.breakpoint.smAndDown&"
import script from "./AppSwitcher.vue?vue&type=script&lang=js&"
export * from "./AppSwitcher.vue?vue&type=script&lang=js&"
import style0 from "./AppSwitcher.vue?vue&type=style&index=0&id=989746d0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "989746d0",
  null
  
)

export default component.exports