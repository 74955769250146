<template>
    <v-menu id="lang-switcher" offset-y left :close-on-content-click="false">
        <template v-slot:activator="{ on }">
            <v-btn id="lang-switcher-btn" v-on="on"
                ><v-icon small class="mr-4">{{ countryFlag($i18n.locale) }}</v-icon
                >{{ $i18n.locale }}
            </v-btn>
        </template>

        <v-list id="locale-list">
            <v-list-item v-for="locale in availableLocales" :key="locale.code" @click="gotoLocalePath(locale.code)">
                <v-list-item-avatar>{{ countryFlag(locale.countryCode) }}</v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-subtitle></v-list-item-subtitle>
                    <v-list-item-title>{{ locale.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import emojiFlags from 'emoji-flags';

export default {
    name: 'LangSwitcher',
    computed: {
        availableLocales() {
            return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
        },
    },
    methods: {
        gotoLocalePath(locale) {
            window.location = this.switchLocalePath(locale.toLocaleLowerCase());
        },
        countryFlag(countryCode) {
            // en or undefined default to "us"
            const code = countryCode != null && countryCode !== 'en' ? countryCode : 'us';
            const flagMeta = emojiFlags.countryCode(code);

            return flagMeta ? flagMeta.emoji : null;
        },
    },
};
</script>

<style scoped lang="scss">
#lang-switcher-btn {
    background-color: #002925 !important;
}

#locale-list {
    margin-top: 6px;
}
</style>
