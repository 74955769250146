// noinspection NpmUsedModulesInstalled
import Vue from 'vue';
import { capitalize } from 'lodash';
import capitalizeWords from '@/helpers/capitalize-words';

// Vue.directive('NAME', {
//     update(el) {
//     },
// });

Vue.filter('capitalize', function (value) {
    if (!value) return '';

    return capitalize(value.toString());
});

Vue.filter('capitalizeWords', function (value) {
    if (!value) return '';

    return capitalizeWords(value);
});
