import * as _countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';

_countries.registerLocale(en);

export const countries = _countries;

/**
 * @param {string} lang
 * @returns {[]}
 */
export function getFormChoices(lang = 'en') {
    const choices = [];
    Object.entries(_countries.getNames(lang)).forEach(([value, name]) => {
        // IMPORTANT type cast "value" to string
        choices.push({ text: name, value: value.toString() });
    });

    return choices;
}

/**
 * @param {string} alpha2Code
 * @param {string} lang
 * @returns {string}
 */
export function getNameByAlpha2(alpha2Code, lang = 'en') {
    return _countries.getName(alpha2Code, lang);
}
