import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _58ed4696 = () => interopDefault(import('../pages/bnk-system/index.vue' /* webpackChunkName: "pages/bnk-system/index" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e336341a = () => interopDefault(import('../pages/legal.vue' /* webpackChunkName: "pages/legal" */))
const _aa18caba = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0090c240 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _5574d301 = () => interopDefault(import('../pages/monitoring/index.vue' /* webpackChunkName: "pages/monitoring/index" */))
const _245a834d = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _26aa9e6b = () => interopDefault(import('../pages/reporting/index.vue' /* webpackChunkName: "pages/reporting/index" */))
const _57104ee2 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _e96c947e = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _4f813b12 = () => interopDefault(import('../pages/admin/iam/index.vue' /* webpackChunkName: "pages/admin/iam/index" */))
const _30eeb930 = () => interopDefault(import('../pages/admin/system/index.vue' /* webpackChunkName: "pages/admin/system/index" */))
const _62491479 = () => interopDefault(import('../pages/reporting/new.vue' /* webpackChunkName: "pages/reporting/new" */))
const _286df15c = () => interopDefault(import('../pages/users/profile.vue' /* webpackChunkName: "pages/users/profile" */))
const _81d6b74a = () => interopDefault(import('../pages/users/service-accounts.vue' /* webpackChunkName: "pages/users/service-accounts" */))
const _49efde85 = () => interopDefault(import('../pages/bnk-system/maintenance/_id/index.vue' /* webpackChunkName: "pages/bnk-system/maintenance/_id/index" */))
const _e08af528 = () => interopDefault(import('../pages/monitoring/_id/index.vue' /* webpackChunkName: "pages/monitoring/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bnk-system",
    component: _58ed4696,
    name: "bnk-system___en"
  }, {
    path: "/de",
    component: _3d6810e8,
    name: "index___de"
  }, {
    path: "/legal",
    component: _e336341a,
    name: "legal___en"
  }, {
    path: "/login",
    component: _aa18caba,
    name: "login___en"
  }, {
    path: "/logout",
    component: _0090c240,
    name: "logout___en"
  }, {
    path: "/monitoring",
    component: _5574d301,
    name: "monitoring___en"
  }, {
    path: "/privacy-policy",
    component: _245a834d,
    name: "privacy-policy___en"
  }, {
    path: "/reporting",
    component: _26aa9e6b,
    name: "reporting___en"
  }, {
    path: "/reset-password",
    component: _57104ee2,
    name: "reset-password___en"
  }, {
    path: "/terms",
    component: _e96c947e,
    name: "terms___en"
  }, {
    path: "/admin/iam",
    component: _4f813b12,
    name: "admin-iam___en"
  }, {
    path: "/admin/system",
    component: _30eeb930,
    name: "admin-system___en"
  }, {
    path: "/de/bnk-system",
    component: _58ed4696,
    name: "bnk-system___de"
  }, {
    path: "/de/legal",
    component: _e336341a,
    name: "legal___de"
  }, {
    path: "/de/login",
    component: _aa18caba,
    name: "login___de"
  }, {
    path: "/de/logout",
    component: _0090c240,
    name: "logout___de"
  }, {
    path: "/de/monitoring",
    component: _5574d301,
    name: "monitoring___de"
  }, {
    path: "/de/privacy-policy",
    component: _245a834d,
    name: "privacy-policy___de"
  }, {
    path: "/de/reporting",
    component: _26aa9e6b,
    name: "reporting___de"
  }, {
    path: "/de/reset-password",
    component: _57104ee2,
    name: "reset-password___de"
  }, {
    path: "/de/terms",
    component: _e96c947e,
    name: "terms___de"
  }, {
    path: "/reporting/new",
    component: _62491479,
    name: "reporting-new___en"
  }, {
    path: "/users/profile",
    component: _286df15c,
    name: "users-profile___en"
  }, {
    path: "/users/service-accounts",
    component: _81d6b74a,
    name: "users-service-accounts___en"
  }, {
    path: "/de/admin/iam",
    component: _4f813b12,
    name: "admin-iam___de"
  }, {
    path: "/de/admin/system",
    component: _30eeb930,
    name: "admin-system___de"
  }, {
    path: "/de/reporting/new",
    component: _62491479,
    name: "reporting-new___de"
  }, {
    path: "/de/users/profile",
    component: _286df15c,
    name: "users-profile___de"
  }, {
    path: "/de/users/service-accounts",
    component: _81d6b74a,
    name: "users-service-accounts___de"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index___en"
  }, {
    path: "/de/bnk-system/maintenance/:id",
    component: _49efde85,
    name: "bnk-system-maintenance-id___de"
  }, {
    path: "/bnk-system/maintenance/:id",
    component: _49efde85,
    name: "bnk-system-maintenance-id___en"
  }, {
    path: "/de/monitoring/:id",
    component: _e08af528,
    name: "monitoring-id___de"
  }, {
    path: "/monitoring/:id",
    component: _e08af528,
    name: "monitoring-id___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
