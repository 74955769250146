import localebbf3bcd8 from '../../build/locales/en-US.mjs'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: true,
  locales: [{"code":"en","countryCode":"us","name":"English","file":"en-US.mjs"},{"code":"de","countryCode":"de","name":"German","file":"de_DE.mjs"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "build/locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","countryCode":"us","name":"English","file":"en-US.mjs"},{"code":"de","countryCode":"de","name":"German","file":"de_DE.mjs"}],
  localeCodes: ["en","de"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "U",
  6: "S",
  7: ".",
  8: "m",
  9: "j",
  10: "s",
  11: "\"",
  12: ":",
  13: "\"",
  14: ".",
  15: ".",
  16: "/",
  17: "b",
  18: "u",
  19: "i",
  20: "l",
  21: "d",
  22: "/",
  23: "l",
  24: "o",
  25: "c",
  26: "a",
  27: "l",
  28: "e",
  29: "s",
  30: "/",
  31: "e",
  32: "n",
  33: "-",
  34: "U",
  35: "S",
  36: ".",
  37: "m",
  38: "j",
  39: "s",
  40: "\"",
  41: ",",
  42: "\"",
  43: "d",
  44: "e",
  45: "_",
  46: "D",
  47: "E",
  48: ".",
  49: "m",
  50: "j",
  51: "s",
  52: "\"",
  53: ":",
  54: "\"",
  55: ".",
  56: ".",
  57: "/",
  58: "b",
  59: "u",
  60: "i",
  61: "l",
  62: "d",
  63: "/",
  64: "l",
  65: "o",
  66: "c",
  67: "a",
  68: "l",
  69: "e",
  70: "s",
  71: "/",
  72: "d",
  73: "e",
  74: "_",
  75: "D",
  76: "E",
  77: ".",
  78: "m",
  79: "j",
  80: "s",
  81: "\"",
  82: "}",
}

export const localeMessages = {
  'en-US.mjs': () => Promise.resolve(localebbf3bcd8),
  'de_DE.mjs': () => import('../../build/locales/de_DE.mjs' /* webpackChunkName: "lang-de_DE.mjs" */),
}
