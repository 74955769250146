import checkTypes from 'check-types';
import getWindTurbineName from '@/helpers/get-wind-turbine-name';
import capitalizeWords from '@/helpers/capitalize-words';
import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    sidebar: false,
    poi: null,
    searchPoi: null,
    selectedPoi: null,
    searchPoiResult: new Map(),
});

export const mutations = {
    clear(state) {
        state.sidebar = false;
        state.poi = null;
        state.searchPoi = null;
        state.selectedPoi = null;
        state.searchPoiResult = new Map();
    },
    setSidebar(state, value) {
        state.sidebar = valueTransform.toBoolean(value);
    },
    setPoi(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.poi = value;
    },
    setSearchPoi(state, value) {
        state.searchPoi = valueTransform.toString(value);
    },
    setSelectedPoi(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.selectedPoi = value;
    },
    setSearchPoiResult(state, value) {
        value = value || [];
        state.searchPoiResult = new Map();
        value.forEach(function (item) {
            state.searchPoiResult.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.searchPoiResult = new Map(state.searchPoiResult.entries());
    },
};

export const actions = {
    async searchPoi({ commit }, value) {
        // to reduce load on the backend, only request a search if the string is at least one single character long
        if (!checkTypes.string(value) || value.length < 1) {
            return;
        }
        const { items: windTurbines } = await this.$axios.$get('/api/operating/wind-turbines', {
            params: { search: value },
        });
        const { items: windParks } = await this.$axios.$get('/api/operating/wind-parks', { params: { search: value } });
        const { items: atsInstallations } = await this.$axios.$get('/api/operating/ats-installations', {
            params: { search: value },
        });

        const searchResult = [];
        for (const turbine of windTurbines) {
            const { _id, manufacturer, serialNumber, operatorIdent } = turbine;
            // crate a turbine name for viewing purposes
            const name = getWindTurbineName({ id: _id, manufacturer, serialNumber, operatorIdent });
            searchResult.push({
                _id,
                name,
                typeName: capitalizeWords(this.app.i18n.tc('wind_turbine', 1)),
                type: 'wind_turbine',
            });
        }
        for (const windPark of windParks) {
            const { _id, name } = windPark;
            searchResult.push({
                _id,
                name,
                typeName: capitalizeWords(this.app.i18n.t('wind_park')),
                type: 'wind_park',
            });
        }
        for (const atsInstallation of atsInstallations) {
            const { _id, name } = atsInstallation;
            searchResult.push({ _id, name, typeName: 'ATS Installation', type: 'ats_installation' });
        }

        commit('setSearchPoiResult', searchResult);
    },
};
