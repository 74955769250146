/**
 * Handles a VUEX store value of simple scalar data type boolean.
 *
 * @param {*} value
 * @param {{[nullable]: boolean, [boolifyString]: true}} [config]
 * @return {boolean|null}
 */
export function toBoolean(value, config) {
    const defaultConfig = { boolifyString: true, nullable: false };
    config = config || {};
    const { boolifyString, nullable } = { ...defaultConfig, ...config };
    if (nullable) {
        if (value == null) return null;
    }
    if (boolifyString && typeof value === 'string') {
        // convert string values into boolean value
        if (value.toLowerCase() === 'true') return true;
        if (value.toLowerCase() === 'false') return false;
    }

    if (typeof value !== 'boolean') throw new Error('Failed to convert value');

    return value;
}

/**
 * Handles a VUEX store value of simple scalar data type string.
 *
 * @param {string|*} value
 * @param {{[nullable]: boolean, [postProcess]: Function}} [config] - If nullable is true, null/undefined/empty string values are allowed
 * @return {string|null}
 */
export function toString(value, config) {
    const defaultConfig = { nullable: true, postProcess: undefined };
    config = config || {};
    const { nullable, postProcess } = { ...defaultConfig, ...config };
    if (nullable) {
        if (value == null || value?.trim() === '') return null;
    }
    if (!_isScalarValue(value)) {
        throw new Error('Function only takes scalar values');
    }
    value = value.toString();
    value = postProcess ? postProcess(value) : value;

    if (typeof value !== 'string') throw new Error('Failed to convert value');

    return value;
}

/**
 * Handles a VUEX store value of simple scalar data type string list.
 *
 * @param {string|*} values
 * @param {{[nullable]: boolean, [postProcess]: Function}} [config] - If nullable is true, null/undefined/empty string values are allowed
 * @return {string|null}
 */
export function toStringList(values, config) {
    const defaultConfig = { nullable: true, postProcess: undefined };
    config = config || {};
    const { nullable, postProcess } = { ...defaultConfig, ...config };
    if (nullable) {
        if (values == null) return null;
    }
    // convert to array
    values = Array.isArray(values) ? values : [values];

    values = values.map((value) => {
        value = value.toString();

        if (postProcess) {
            values.map((value) => {
                value = postProcess(value);
            });
        }

        if (typeof value !== 'string') throw new Error('Failed to convert value');

        return value;
    });

    values.sort((a, b) => a - b);

    return values;
}

/**
 * Handles a VUEX store value of simple scalar data type integer.
 *
 * @param {string|*} value
 * @param {{[nullable]: boolean, [postProcess]: Function}} [config] - If nullable is true, null/undefined values are allowed
 * @return {number|null}
 */
export function toInteger(value, config) {
    const defaultConfig = { nullable: true, postProcess: undefined };
    config = config || {};
    const { nullable, postProcess } = { ...defaultConfig, ...config };
    if (nullable) {
        if (value == null) return null;
    }
    if (isNaN(value)) {
        throw new TypeError('Function only takes numbers');
    }
    value = parseInt(value);

    if (typeof value !== 'number') throw new Error('Failed to convert value');

    return postProcess ? postProcess(value) : value;
}

/**
 * Handles a VUEX store value of simple scalar data type integer list.
 *
 * @param {string|*} values
 * @param {{[nullable]: boolean, [postProcess]: Function}} [config] - If nullable is true, null/undefined values are allowed
 * @return {number|null}
 */
export function toIntegerList(values, config) {
    const defaultConfig = { nullable: true, postProcess: undefined };
    config = config || {};
    const { nullable, postProcess } = { ...defaultConfig, ...config };
    if (nullable) {
        if (values == null) return null;
    }
    // convert to array
    values = Array.isArray(values) ? values : [values];

    values = values.map((value) => {
        if (isNaN(value)) {
            throw new TypeError('Function only takes numbers');
        }

        value = parseInt(value);

        if (postProcess) {
            values.map((value) => {
                value = postProcess(value);
            });
        }

        if (typeof value !== 'number') throw new Error('Failed to convert value');

        return value;
    });

    values.sort((a, b) => a - b);

    return values;
}

/**
 * Handles a VUEX store value of simple scalar data type float.
 *
 * @param {string|*} value
 * @param {{[nullable]: boolean, [postProcess]: Function}} [config] - If nullable is true, null/undefined values are allowed
 * @return {number|null}
 */
export function toFloat(value, config) {
    const defaultConfig = { nullable: true, postProcess: undefined };
    config = config || {};
    const { nullable, postProcess } = { ...defaultConfig, ...config };
    if (nullable) {
        if (value == null) return null;
    }
    if (isNaN(value)) {
        throw new TypeError('Function only takes numbers');
    }
    value = parseFloat(value);

    if (typeof value !== 'number') throw new Error('Failed to convert value');

    return postProcess ? postProcess(value) : value;
}

/**
 * Handles a VUEX store value of simple scalar data type float list.
 *
 * @param {string|*} values
 * @param {{[nullable]: boolean, [postProcess]: Function}} [config] - If nullable is true, null/undefined values are allowed
 * @return {number|null}
 */
export function toFloatList(values, config) {
    const defaultConfig = { nullable: true, postProcess: undefined };
    config = config || {};
    const { nullable, postProcess } = { ...defaultConfig, ...config };
    if (nullable) {
        if (values == null) return null;
    }
    // convert to array
    values = Array.isArray(values) ? values : [values];

    values = values.map((value) => {
        if (isNaN(value)) {
            throw new TypeError('Function only takes numbers');
        }

        value = parseFloat(value);

        if (postProcess) {
            values.map((value) => {
                value = postProcess(value);
            });
        }

        if (typeof value !== 'number') throw new Error('Failed to convert value');

        return value;
    });

    return values;
}

function _isScalarValue(value) {
    return typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean';
}
