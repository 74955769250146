import { extend } from 'vee-validate';
import Merkator from 'openaip-merkator';
import { DateTime } from 'luxon';
import { required, email, regex, min, max, min_value, max_value, required_if, integer, numeric, is } from 'vee-validate/dist/rules';
import { countries } from '@/helpers/choices/countries';

// Imported rules

extend('is', is);
extend('numeric', numeric);
extend('integer', integer);
extend('email', email);
extend('min', min);
extend('max', max);
extend('min_value', min_value);
extend('max_value', max_value);
extend('required_if', required_if);
extend('required', {
    ...required,
    message: 'This field is required',
});
extend('regex', regex);
extend('regexPassword', {
    ...regex,
    message: 'Password is required and must be more than 20 characters long and contain at least an upper case, a' + ' lower case character and a number',
});
extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Password confirmation does not match',
});
extend('positive', {
    validate: (value) => {
        return value >= 0;
    },
    message: 'Field must be a positive number',
});
// add globally used validation rules here => specific rules can be added in each component separately
extend('coordinate', {
    validate: (value) => {
        try {
            let merkator = new Merkator();
            merkator.readString(value);

            return true;
        } catch (e) {
            return false;
        }
    },
    message: 'Field must be a valid coordinate string.',
});
extend('future_date', {
    validate(value) {
        const inputDatetime = DateTime.utc(value);
        const now = DateTime.utc();

        return inputDatetime > now;
    },
    message: 'Date must be greater than now.',
});
extend('country', {
    validate(value) {
        return countries.isValid(value);
    },
    message: '{_value_} is not a valid ISO 3166-1 Alpha-2 country code.',
});
