<template>
    <v-col cols="12">
        <v-alert v-for="(item, index) in messages" :key="index" type="info" text>{{ item.message }}</v-alert>
    </v-col>
</template>

<script>
import rbacMeta from '@/mixins/rbac-meta';

export default {
    name: 'SystemMessages',
    mixins: [rbacMeta],
    computed: {
        messages() {
            return this.$store.getters['systemMessage/list/getItems'];
        },
    },
};
</script>
