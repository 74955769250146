export default class {
    static log(message) {
        if (process.env.NODE_ENV === 'development') {
            console.log(message);
        }
    }
    static warn(message) {
        console.warn(message);
    }
    static error(message) {
        console.warn(message);
    }
}
