import { Enum } from '@/helpers/choices/enum';

export class ManufacturerEnum extends Enum {}

ManufacturerEnum.values = {
    AERODYN: 0,
    AMPERAX: 1,
    ENERCON: 2,
    FUEHRLANDER: 3,
    GENERAL_ELECTRIC: 4,
    NORDEX: 5,
    SCHUETZ: 6,
    SENVION: 7,
    SIEMENS: 8,
    SUEDWIND: 9,
    VENSYS: 10,
    VESTAS: 11,
    WIND_WORLD_AS: 12,
    NEG_MICON: 13,
    AN_BONUS: 14,
    ENO_ENERGY: 15,
    GAMESA: 16,
    SKY_SAILS: 17,
    TACKE: 18,
    KENERSYS: 19,
    BARD: 20,
    SIEMENS_GAMESA: 21,
    ADWEN: 22,
    MULTIBRID: 23,
    LAGERWEY: 24,
};

ManufacturerEnum.names = {
    0: 'Aerodyn',
    1: 'Amperax',
    2: 'Enercon',
    3: 'Führlander',
    4: 'General Electric',
    5: 'Nordex',
    6: 'Schütz',
    7: 'Senvion',
    8: 'Siemens',
    9: 'Südwind',
    10: 'Vensys',
    11: 'Vestas',
    12: 'Wind World A/S',
    13: 'NEG Micon',
    14: 'AN Bonus',
    15: 'ENO Energy',
    16: 'Gamesa',
    17: 'Sky Sails',
    18: 'Tacke',
    19: 'Kenersys',
    20: 'BARD',
    21: 'Siemens Gamesa',
    22: 'Adwen',
    23: 'Multibrid',
    24: 'Lagerwey',
};

Object.freeze(ManufacturerEnum);
