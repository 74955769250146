import AuthUser from '~/helpers/auth-user';

export default ({ app, store, redirect }) => {
    app.router.afterEach(async (to, from) => {
        const authUser = AuthUser.loadFromStorage();
        // handle request depending on system state
        await _handleSystemState({ store, redirect, authUser });
    });
};

async function _handleSystemState({ store, authUser, redirect }) {
    const maintenance = store.state.maintenanceMode;
    // if system is in maintenance mode, on privileged users are allowed to stays, all others are logged out automatically
    if (maintenance && authUser?.isAdmin() === false) {
        return redirect('/logout?deferNotify=systemMaintenance');
    }
}
