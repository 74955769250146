export const deferNotify = Object.assign(
    {},
    {
        passwordReset: {
            message: 'Send password reset email. Please check your inbox!',
            type: 'success',
        },
        systemMaintenance: {
            message: "System is undergoing maintenance. We'll be back shortly.",
            type: 'warning',
        },
        appAccessDenied: {
            message: "You don't have the required permissions to access this application",
            type: 'warning',
        },
    }
);
