import DevLogger from '@/helpers/dev-logger';
import { REQUEST_DEFAULT_TIMEOUT } from '@/globals';
import AuthUser from '~/helpers/auth-user';

const API_URLS = ['/api/iam', '/api/tiles', '/api/monitoring', '/api/operating', '/api/messaging', '/api/notification', '/api/reporting', '/api/ext-services'];

export default function ({ $axios, redirect }) {
    // IMPORTANT set a default request timeout so we don't get stalled and blocking requests
    $axios.defaults.timeout = REQUEST_DEFAULT_TIMEOUT;

    $axios.onRequest(async (config) => {
        let append = false;
        // only append token if available
        const authUser = AuthUser.loadFromStorage();
        const token = authUser.getToken();
        if (token) {
            for (const url of API_URLS) {
                if (config.url.includes(url)) {
                    append = true;
                    if (config.headers['Authorization']) {
                        DevLogger.log(`Known request url ${config.url} intercepted. Authorization headers already set.`);
                    } else {
                        DevLogger.log(`Known request url ${config.url} intercepted. Appending Bearer token.`);
                        config.headers['Authorization'] = `Bearer ${token}`;
                    }
                }
            }
        }
        if (process.env.NODE_ENV === 'development' && process.env.CACHE_BYPASS === true) {
            config.headers['x-rediscache-bypass'] = true;
        }

        if (!append && token) {
            DevLogger.log(`Unknown request url ${config.url}. Not appending Bearer token.`);
        } else if (!append && !token) {
            DevLogger.log(`Cannot append token to url ${config.url}. Token not set or empty.`);
        }

        // IMPORTANT https://github.com/svrcekmichal/redux-axios-middleware/issues/83#issuecomment-407466397
        // Required for config to be passed to other middleware that cancels requests and requires cancelToken.
        // Otherwise a "TypeError: Cannot read property 'cancelToken' of undefined" is thrown
        return config;
    });

    // IMPORTANT don't make callback func async as this will not intercept errors anymore
    $axios.onResponseError((err) => {
        DevLogger.error(err);

        // IMPORTANT do not add 404 here => must be handled separately
        const { status } = err.response || {};
        switch (status) {
            case 503:
                if (err.message === 'Service unavailable due to system maintenance') {
                    return redirect('/logout?forced_logout=maintenance');
                }
                break;
        }
    });
}
