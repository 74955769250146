/**
 * @type {Readonly<{}>}
 */
export const permissions = Object.freeze({
    MONITORING_SYSTEM_STATUS_UPDATE: 'monitoring:system-status:update',
    MONITORING_APP_ACCESS: 'monitoring:app:access',
    MONITORING_APP_SETTINGSREAD: 'monitoring:app:settings-read',
    MONITORING_APP_SETTINGSUPDATE: 'monitoring:app:settings-update',
    MONITORING_CACHE_CLEAR: 'monitoring:cache:clear',
    OPERATING_APP_ACCESS: 'operating:app:access',
    OPERATING_ATS_READ: 'operating:ats:read',
    OPERATING_ATSINSTALLATION_READ: 'operating:ats-installation:read',
    OPERATING_MAINTENANCEACTION_READ: 'operating:maintenance-action:read',
    OPERATING_WINDTURBINE_READ: 'operating:wind-turbine:read',
    REPORTING_REPORT_READ: 'reporting:report:read',
    REPORTING_REPORT_CREATE: 'reporting:report:create',
    IAM_PROFILE_READ: 'iam:profile:read',
    IAM_PROFILE_UPDATE: 'iam:profile:update',
    IAM_USER_READ: 'iam:user:read',
    IAM_USER_IMPERSONATE: 'iam:user:impersonate',
    NOTIFICATION_NOTIFICATION_READ: 'notification:notification:read',
    MESSAGING_MESSAGE_CREATE: 'messaging:message:create',
    TILES_TILES_BNK_READ: 'tiles:tiles:bnk:read',
});

/**
 * @type {Readonly<{}>}
 */
export const roles = Object.freeze({
    ADMINISTRATOR: 'ADMINISTRATOR',
    OPERATING_ADMIN: 'OPERATING_ADMIN',
    MONITORING_ADMIN: 'MONITORING_ADMIN',
    BUSINESSENTITY_USER: 'BUSINESS_ENTITY_USER',
    BNK_PORTAL_CONTENT_ADMIN: 'BNK_PORTAL_CONTENT_ADMIN',
    BNK_PORTAL_SITE_ADMIN: 'BNK_PORTAL_SITE_ADMIN',
    BNK_PORTAL_EDITOR: 'BNK_PORTAL_EDITOR',
    BNK_PORTAL_VIEWER: 'BNK_PORTAL_VIEWER',
});
