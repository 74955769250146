<template>
    <v-snackbar v-model="notifyShow" :color="notifyType" :timeout="notifyTimeout" top center app>
        {{ notifyText }}
        <v-btn text color="white" @click="notifyShow = false">Close</v-btn>
    </v-snackbar>
</template>

<script>
export default {
    name: 'Notification',
    computed: {
        notifyShow: {
            get() {
                return this.$store.state.notifyShow;
            },
            set(value) {
                this.$store.commit('setNotifyShow', value, { root: true });
            },
        },
        notifyText() {
            return this.$store.state.notifyText;
        },
        notifyType() {
            return this.$store.state.notifyType;
        },
        notifyTimeout() {
            return this.$store.state.notifyTimeout;
        },
    },
};
</script>
