// ##################
// general
// ##################

export const APP_NAME = 'Monitoring';
export const APP_CONTEXT = 'bnk-monitoring';
export const PERSIST_KEY = '@bnkportal';
export const PAGE_TITLE = 'BNK Monitoring';
export const REQUEST_DEFAULT_TIMEOUT = '15000';
export const REQUEST_POLLING_TIMEOUT = '3000';
export const DEBOUNCE_TIMEOUT = 1200;
export const LIST_ITEMS_PER_PAGE_DEFAULT = 50;
export const LIST_ITEMS_PER_PAGE = [20, 50, 100];
export const NOT_AVAILABLE = 'N/A';
export const NO_VALUE_PLACEHOLDER = 'NIL';
export const MONTHS = [];
export const DAYS_PER_MONTH = 30.4167;
export const UNLIMITED_ITEMS = 9999999;
export const DATE_FORMAT = 'yyyy-MM-dd';
export const MAX_REPORTING_DAYS = 60;
export const MESSAGE_CLIPBOARD_CONTENT = 'Content copied to clipboard';
export const QUERY_PARAM_ORIGIN_URL = 'originUrl';
// new ADLS client state logic may send data that is at most 15 minutes old (if no state change occurred in this time)
export const BNK_STATUS_IS_ALIVE_THRESHOLD = 15 * 60;
export const TTW_BACKEND_OPS = 1500;

// ##################
// messages / hints (only required for error handler)
// ##################

export const MESSAGE_INVALID_PAYLOAD = 'Application cannot process sent payload';
export const MESSAGE_ERROR_APPLICATION = 'Application Error';
export const MESSAGE_ERROR_DATA_LOAD = 'Loading data from backend failed';
export const MESSAGE_ERROR_SERVICE_UNAVAILABLE = 'Service unavailable';
export const MESSAGE_FORM_INPUT_INVALID = 'Form input required or missing. Please review form fields.';

// ##################
// maps
// ##################

export const MAP_CENTER = [8.657871430057156, 49.29286733869293];
export const MAP_MIN_ZOOM = 7;
export const MAP_MAX_ZOOM = 18;
export const MAP_INITIAL_NEW_ZOOM = 7;
export const MAP_INITIAL_EDIT_ZOOM = 12;
export const MAP_INITIAL_VIEW_ZOOM = 14;
