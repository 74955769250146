<template>
    <v-app>
        <v-main>
            <notification />
            <nuxt />
        </v-main>
    </v-app>
</template>

<script>
import Notification from '@/components/Notification';
import BaseLayout from '@/layouts/base';
export default {
    name: 'FullCanvas',
    components: { Notification },
    extends: BaseLayout,
};
</script>

<style lang="scss">
footer {
    margin-top: 20em;
}
</style>
