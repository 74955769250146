import checkTypes from 'check-types';
import { DateTime } from 'luxon';
import prepareTurbineStats from '@/helpers/prepare-turbine-stats';
import { DATE_FORMAT } from '@/globals';
import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    id: null,
    hasStatistics: false,
    availableDates: [],
    startDate: null,
    endDate: null,
    days: null,
    durationSystemOk: null,
    durationNighttime: null,
    durationLightsOn: null,
    durationAutoLightsOn: null,
    durationManualLightsOn: null,
    durationFailSafeLightsOn: null,
    percentSystemOk: null,
    percentLightsOn: null,
    percentAutoLightsOn: null,
    percentManualLightsOn: null,
    percentFailSafeLightsOn: null,
});

export const mutations = {
    clear(state) {
        state.id = null;
        state.hasStatistics = false;
        state.availableDates = [];
        state.startDate = null;
        state.endDate = null;
        state.days = null;
        state.durationNighttime = null;
        state.durationSystemOk = null;
        state.durationLightsOn = null;
        state.durationAutoLightsOn = null;
        state.durationFailSafeLightsOn = null;
        state.percentSystemOk = null;
        state.percentLightsOn = null;
        state.percentAutoLightsOn = null;
        state.percentManualLightsOn = null;
        state.percentFailSafeLightsOn = null;
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setHasStatistics(state, value) {
        state.hasStatistics = valueTransform.toBoolean(value);
    },
    setAvailableDates(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyString(value);
        state.availableDates = value;
    },
    setStartDate(state, value) {
        state.startDate = valueTransform.toString(value, { nullable: false });
    },
    setEndDate(state, value) {
        state.endDate = valueTransform.toString(value, { nullable: false });
    },
    setDays(state, value) {
        state.days = valueTransform.toInteger(value, { nullable: false });
    },
    setDurationSystemOk(state, value) {
        state.durationSystemOk = valueTransform.toString(value, { nullable: false });
    },
    setDurationNighttime(state, value) {
        state.durationNighttime = valueTransform.toString(value, { nullable: false });
    },
    setDurationLightsOn(state, value) {
        state.durationLightsOn = valueTransform.toString(value, { nullable: false });
    },
    setDurationAutoLightsOn(state, value) {
        state.durationAutoLightsOn = valueTransform.toString(value, { nullable: false });
    },
    setDurationFailSafeLightsOn(state, value) {
        state.durationFailSafeLightsOn = valueTransform.toString(value, { nullable: false });
    },
    setDurationManualLightsOn(state, value) {
        state.durationManualLightsOn = valueTransform.toString(value, { nullable: false });
    },
    setPercentSystemOk(state, value) {
        state.percentSystemOk = valueTransform.toFloat(value, { nullable: false });
    },
    setPercentLightsOn(state, value) {
        state.percentLightsOn = valueTransform.toFloat(value, { nullable: false });
    },
    setPercentAutoLightsOn(state, value) {
        state.percentAutoLightsOn = valueTransform.toFloat(value, { nullable: false });
    },
    setPercentManualLightsOn(state, value) {
        state.percentManualLightsOn = valueTransform.toFloat(value, { nullable: false });
    },
    setPercentFailSafeLightsOn(state, value) {
        state.percentFailSafeLightsOn = valueTransform.toFloat(value, { nullable: false });
    },
};

export const actions = {
    async load({ commit }, { id, start, end }) {
        checkTypes.assert.nonEmptyString(id);
        if (start) {
            checkTypes.assert.nonEmptyString(start);
        } else {
            // if not defined, request data for "today"
            start = DateTime.utc().toFormat(DATE_FORMAT);
        }
        if (end) {
            checkTypes.assert.nonEmptyString(end);
        }

        const turbineStats = await this.$axios.$get(`/api/reporting/metrics/${id}/statistics`, {
            params: { startDate: start, endDate: end },
        });

        // IMPORTANT only add statistics if available
        if (!turbineStats) {
            return;
        }

        prepareTurbineStats(turbineStats);

        let {
            startDate,
            endDate,
            days,
            percSystemOk,
            percLightsOn,
            percAutoLightsOn,
            percManualLightsOn,
            percFailSafeLightsOn,
            humanRead: { durationSystemOk, durationNighttime, durationLightsOn, durationAutoLightsOn, durationManualLightsOn, durationFailSafeLightsOn },
        } = turbineStats;

        // if not stats have been found, days is set to 0
        commit('setHasStatistics', days > 0);
        commit('setId', id);
        commit('setStartDate', startDate);
        commit('setEndDate', endDate);
        commit('setDays', days);
        commit('setDurationSystemOk', durationSystemOk);
        commit('setDurationNighttime', durationNighttime);
        commit('setDurationLightsOn', durationLightsOn);
        commit('setDurationAutoLightsOn', durationAutoLightsOn);
        commit('setDurationManualLightsOn', durationManualLightsOn);
        commit('setDurationFailSafeLightsOn', durationFailSafeLightsOn);
        commit('setPercentSystemOk', percSystemOk);
        commit('setPercentLightsOn', percLightsOn);
        commit('setPercentAutoLightsOn', percAutoLightsOn);
        commit('setPercentManualLightsOn', percManualLightsOn);
        commit('setPercentFailSafeLightsOn', percFailSafeLightsOn);
    },
    async loadAvailableDates({ commit }, { id }) {
        const data = await this.$axios.$get(`/api/reporting/metrics/${id}/reports`);
        // cut down to expected date format "2020-02-20" (is already ISO string...)
        const availableDates = data.map((meta) => meta.timestamp.substring(0, 10));
        commit('setAvailableDates', availableDates);
    },
};
