import checkTypes from 'check-types';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/globals';
import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    id: null,
    name: null,
    serialNumber: null,
    deviceId: null,
    maintenanceActions: new Map(),
});

export const getters = {
    getMaintenanceActions: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        let list = Array.from(state.maintenanceActions.values());
        // sort list depending on date and timezone
        list.sort((a, b) => {
            const dateA = DateTime.fromFormat(a.date, DATE_FORMAT, { zone: a.timezone });
            const dateB = DateTime.fromFormat(b.date, DATE_FORMAT, { zone: b.timezone });

            if (dateA < dateB) {
                return -1;
            }
            if (dateA > dateB) {
                return 1;
            }
            return 0;
        });

        return list;
    },
};

export const mutations = {
    clear(state) {
        state.id = null;
        state.name = null;
        state.serialNumber = null;
        state.deviceId = null;
        state.maintenanceActions = new Map();
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setName(state, value) {
        state.name = valueTransform.toString(value, { nullable: false });
    },
    setSerialNumber(state, value) {
        state.serialNumber = valueTransform.toString(value, { nullable: false });
    },
    setDeviceId(state, value) {
        state.deviceId = valueTransform.toString(value, { nullable: false });
    },
    setMaintenanceActions(state, value) {
        value = value || [];
        state.maintenanceActions = new Map();
        value.forEach(function (item) {
            state.maintenanceActions.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.maintenanceActions = new Map(state.maintenanceActions.entries());
    },
    removeMaintenanceAction(state, value) {
        checkTypes.assert.nonEmptyString(value);
        state.maintenanceActions.delete(value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.maintenanceActions = new Map(state.maintenanceActions.entries());
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const { _id, name, ats, maintenanceActions } = await this.$axios.$get(`/api/operating/ats-installations/${id}`, {
            params: {
                populateRefs: true,
            },
        });

        commit('setId', _id);
        commit('setName', name);
        commit('setMaintenanceActions', maintenanceActions ?? []);

        // ats is optional
        if (ats != null) {
            const { serialNumber, deviceId } = ats;
            commit('setSerialNumber', serialNumber);
            commit('setDeviceId', deviceId);
        }
    },
    async delete({ state }, { atsInstallationId, maintenanceActionId }) {
        await this.$axios.$delete(`/api/operating/ats-installations/${atsInstallationId}/maintenance/actions/${maintenanceActionId}`);
    },
};
