import StackTrace from 'stacktrace-js';

/**
 * URL endpoint of the Stackdriver Error Reporting report API
 */
const baseAPIUrl = 'https://clouderrorreporting.googleapis.com/v1beta1/projects/';
/**
 * An Error handler that sends errors to the Stackdriver Error Reporting API
 */
const StackdriverErrorReporter = function () {};

/**
 * Initialize the StackdriverErrorReporter object
 * @param {Object} config - the init configuration
 * @param {Object} [config.context={}] - the context in which the error occurred
 * @param {string} [config.context.user] - the user who caused or was affected by the error
 * @param {String} config.key - the API key to use to call the API
 * @param {String} config.projectId - the Google Cloud Platform project ID to report errors to
 * @param {String} [config.service=web] - service identifier
 * @param {String} [config.version] - version identifier
 * @returns {StackdriverErrorReporter} The initialized StackdriverErrorReporter object
 */
StackdriverErrorReporter.init = function (config) {
    if (!config.apiKey) {
        throw new Error('Cannot initialize: No API key, target url or custom reporting function provided.');
    }
    if (!config.projectId) {
        throw new Error('Cannot initialize: No project ID, target url or custom reporting function provided.');
    }

    this.apiKey = config.apiKey;
    this.projectId = config.projectId;
    this.context = config.context || {};
    this.serviceContext = { service: config.service || 'web', serviceType: config.serviceType };
    if (config.version) {
        this.serviceContext.version = config.version;
    }
    this.sourceReference = { repository: config.repository, revisionId: config.revisionId };

    return this;
};

/**
 * Report an error to the Stackdriver Error Reporting API
 * @param {Error|String} err - The Error object or message string to report
 * @param {Object} options - Configuration for this report
 * @param {number} [options.skipLocalFrames=1] - Omit number of frames if creating stack
 * @returns {Promise} A promise that completes when the report has been sent
 */
StackdriverErrorReporter.report = function (err, options) {
    if (!err) {
        return Promise.reject(new Error('no error to report'));
    }
    options = options || {};

    const payload = {};
    payload.serviceContext = this.serviceContext;
    payload.context = this.context;
    payload.context.httpRequest = {
        userAgent: window.navigator.userAgent,
        url: window.location.href,
    };
    payload.context.sourceReferences = [this.sourceReference];

    let firstFrameIndex = 0;
    if (typeof err == 'string' || err instanceof String) {
        // Transform the message in an error, use try/catch to make sure the stacktrace is populated.
        try {
            throw new Error(err);
        } catch (e) {
            err = e;
        }
        // the first frame when using report() is always this library
        firstFrameIndex = options.skipLocalFrames || 1;
    }

    const reportUrl = baseAPIUrl + this.projectId + '/events:report?key=' + this.apiKey;

    return resolveError(err, firstFrameIndex).then(function (message) {
        payload.message = message;

        return sendErrorPayload(reportUrl, payload);
    });
};

/**
 * Set the user for the current context.
 *
 * @param {string} user - the unique identifier of the user (can be ID, email or custom token) or `undefined` if not logged in
 */
StackdriverErrorReporter.setUser = function (user) {
    this.context.user = user;
};

function resolveError(err, firstFrameIndex) {
    // This will use sourcemaps and normalize the stack frames
    return StackTrace.fromError(err).then(
        function (stack) {
            const lines = [err.toString()];
            // Reconstruct to a JS stackframe as expected by Error Reporting parsers.
            for (let s = firstFrameIndex; s < stack.length; s++) {
                // Cannot use stack[s].source as it is not populated from source maps.
                lines.push(
                    [
                        '    at ',
                        // If a function name is not available '<anonymous>' will be used.
                        stack[s].getFunctionName() || '<anonymous>',
                        ' (',
                        stack[s].getFileName(),
                        ':',
                        stack[s].getLineNumber(),
                        ':',
                        stack[s].getColumnNumber(),
                        ')',
                    ].join('')
                );
            }
            return lines.join('\n');
        },
        function (reason) {
            // Failure to extract stacktrace
            return ['Error extracting stack trace: ', reason, '\n', err.toString(), '\n', '    (', err.file, ':', err.line, ':', err.column, ')'].join('');
        }
    );
}

function sendErrorPayload(url, payload) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

    return new Promise(function (resolve, reject) {
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                const code = xhr.status;
                if (code >= 200 && code < 300) {
                    resolve({ message: payload.message });
                } else {
                    const condition = code ? code + ' http response' : 'network error';
                    reject(new Error(condition + ' on stackdriver report'));
                }
            }
        };
        xhr.send(JSON.stringify(payload));
    });
}

export default StackdriverErrorReporter;
