<template>
    <v-scale-transition>
        <v-btn v-if="showButton" small bottom right fixed color="amber darken-4" class="ma-5" dark fab app @click="goToTop">
            <v-icon>$vuetify.icons.angleUp</v-icon>
        </v-btn>
    </v-scale-transition>
</template>

<script>
export default {
    name: 'ScrollToTopButton',
    data() {
        return {
            offset: 0,
            show: 'false',
        };
    },
    computed: {
        showButton() {
            return this.offset > 200;
        },
    },
    mounted() {
        this.scroll();
    },
    methods: {
        goToTop() {
            this.$vuetify.goTo(0, { duration: 200 });
        },
        scroll() {
            window.onscroll = () => {
                this.offset = document.documentElement.scrollTop;
            };
        },
    },
};
</script>
