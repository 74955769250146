const middleware = {}

middleware['backpedal-url'] = require('../middleware/backpedal-url.js')
middleware['backpedal-url'] = middleware['backpedal-url'].default || middleware['backpedal-url']

middleware['grant-access'] = require('../middleware/grant-access.js')
middleware['grant-access'] = middleware['grant-access'].default || middleware['grant-access']

middleware['unsuppress-middleware'] = require('../middleware/unsuppress-middleware.js')
middleware['unsuppress-middleware'] = middleware['unsuppress-middleware'].default || middleware['unsuppress-middleware']

export default middleware
