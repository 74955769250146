// noinspection NpmUsedModulesInstalled
import Vue from 'vue';
// noinspection NpmUsedModulesInstalled
import colors from 'vuetify/es5/util/colors';
import { camelCase } from 'lodash';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
    faAngleUp,
    faArrowDown,
    faArrowLeft,
    faArrowRightFromBracket,
    faArrowRightFromLine,
    faArrowLeftFromLine,
    faArrowUp,
    faBadgeCheck,
    faBan,
    faBars,
    faBell,
    faBook,
    faBoxOpen,
    faBracketsCurly,
    faCalendarWeek,
    faCaretDown,
    faChartMixed,
    faChartNetwork,
    faCheck,
    faCheckSquare,
    faChevronLeft,
    faChevronRight,
    faCircleCheck,
    faCircleInfo,
    faCircleQuestion,
    faCircleXmark,
    faComputerClassic,
    faCrosshairs,
    faDownload,
    faEllipsisVertical,
    faEmptySet,
    faEnvelope,
    faEnvelopeOpen,
    faEnvelopeOpenText,
    faEye,
    faEyeSlash,
    faFileChartColumn,
    faFileContract,
    faFileLines,
    faFilter,
    faFluxCapacitor,
    faGears,
    faHandshakeSimple,
    faIdCard,
    faInfo,
    faLayerGroup,
    faLayerMinus,
    faLightbulb,
    faLightbulbOn,
    faLightbulbSlash,
    faList,
    faLocationCrosshairs,
    faMagnifyingGlass,
    faMapLocation,
    faMonitorWaveform,
    faMoon,
    faMountain,
    faPenToSquare,
    faPlus,
    faRecycle,
    faRocket,
    faRotateLeft,
    faRulerCombined,
    faSatelliteDish,
    faScaleBalanced,
    faSignalStream,
    faSlidersUp,
    faSquare,
    faSquareMinus,
    faSun,
    faSunHaze,
    faSunrise,
    faSunset,
    faTriangleExclamation,
    faUser,
    faUserHeadset,
    faUserHelmetSafety,
    faUsers,
    faUserSecret,
    faUserTie,
    faWindTurbine,
    faWrench,
    faXmark,
} from '@fortawesome/pro-light-svg-icons';

const iconDefinitions = [
    faAngleUp,
    faArrowDown,
    faArrowLeft,
    faArrowRightFromBracket,
    faArrowUp,
    faBadgeCheck,
    faBan,
    faBars,
    faBell,
    faBook,
    faBoxOpen,
    faBracketsCurly,
    faCalendarWeek,
    faCaretDown,
    faChartMixed,
    faChartNetwork,
    faCheck,
    faCheckSquare,
    faChevronLeft,
    faChevronRight,
    faCircleCheck,
    faCircleInfo,
    faCircleQuestion,
    faCircleXmark,
    faComputerClassic,
    faCrosshairs,
    faDownload,
    faEllipsisVertical,
    faEmptySet,
    faEnvelope,
    faEnvelopeOpen,
    faEnvelopeOpenText,
    faEye,
    faEyeSlash,
    faFileChartColumn,
    faFileContract,
    faFileLines,
    faFilter,
    faFluxCapacitor,
    faGears,
    faHandshakeSimple,
    faIdCard,
    faInfo,
    faLayerGroup,
    faLayerMinus,
    faLightbulb,
    faLightbulbOn,
    faLightbulbSlash,
    faList,
    faLocationCrosshairs,
    faMagnifyingGlass,
    faMapLocation,
    faMonitorWaveform,
    faMoon,
    faMountain,
    faPenToSquare,
    faPlus,
    faRecycle,
    faRocket,
    faRotateLeft,
    faRulerCombined,
    faSatelliteDish,
    faScaleBalanced,
    faSignalStream,
    faSlidersUp,
    faSquare,
    faSquareMinus,
    faSun,
    faSunHaze,
    faSunrise,
    faSunset,
    faTriangleExclamation,
    faUser,
    faUserHeadset,
    faUserHelmetSafety,
    faUsers,
    faUserTie,
    faUserSecret,
    faWindTurbine,
    faWrench,
    faXmark,
    faArrowRightFromLine,
    faArrowLeftFromLine,
];

library.add(...iconDefinitions);

/**
 * Set of fontawesome icons that replace default Vuetify icons.
 */
const vuetifyPresetIcons = {
    // overwrite vuetify defaults => find the complete preset
    // at "node_modules/vuetify/src/services/icons/presets/md.ts"
    complete: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'check'],
        },
    },
    cancel: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'ban'],
        },
    },
    close: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'xmark'],
        },
    },
    delete: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'xmark'],
        },
    }, // delete (e.g. v-chip close)
    clear: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'xmark'],
        },
    },
    success: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'circle-check'],
        },
    },
    info: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'info'],
        },
    },
    warning: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'triangle-exclamation'],
        },
    },
    error: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'triangle-exclamation'],
        },
    },
    prev: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'chevron-left'],
        },
    },
    next: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'chevron-right'],
        },
    },
    checkboxOn: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'check-square'],
        },
    },
    checkboxOff: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'square'],
        },
    },
    checkboxIndeterminate: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'square-minus'],
        },
    },
    delimiter: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'circle'],
        },
    },
    sort: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'arrow-up'],
        },
    },
    expand: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'caret-down'],
        },
    },
    menu: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'bars'],
        },
    },
    subgroup: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'caret-down'],
        },
    },
    dropdown: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'caret-down'],
        },
    },
    edit: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'pen-to-square'],
        },
    },
    first: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'backward-step'],
        },
    },
    last: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'forward-step'],
        },
    },
    file: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fal', 'paperclip'],
        },
    },
};

// create custom app icons
let appIcons = {};
for (const def of iconDefinitions) {
    appIcons[camelCase(def.iconName)] = {
        component: FontAwesomeIcon,
        props: {
            icon: [def.prefix, def.iconName],
        },
    };
}

// Register component globally
Vue.component('font-awesome-icon', FontAwesomeIcon);

export default {
    icons: {
        iconfont: 'faSvg',
        values: Object.assign(vuetifyPresetIcons, appIcons),
    },
    theme: {
        // default theme is light mode
        dark: false,
        themes: {
            dark: {
                primary: colors.blue,
                secondary: colors.teal.darken4,
                accent: colors.teal.accent4,
                error: colors.deepOrange.accent2,
                info: colors.blue.accent1,
                success: colors.green.accent3,
                warning: colors.amber.base,
            },
            light: {
                navbar: '#002925',
                primary: '#5DA62B',
                secondary: colors.blueGrey.lighten1,
                accent: colors.teal.accent4,
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
        },
    },
};
