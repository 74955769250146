<template>
    <div>
        <v-menu offset-y left>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon small>$vuetify.icons.circleInfo</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-list id="help-menu" expand>
                    <v-list-item @click="showModal.contactSupport = true">
                        <v-list-item-action>
                            <v-icon small class="mr-2">$vuetify.icons.envelopeOpenText</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $t('ui.contact_support') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="downloadDocumentation">
                        <v-list-item-action>
                            <v-icon small class="mr-2">$vuetify.icons.download</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $t('ui.portal_documentation') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
        <contact-support-modal :prop-dialog="showModal.contactSupport" @cancel="showModal.contactSupport = false" @close="showModal.contactSupport = false" />
    </div>
</template>

<script>
import rbacMeta from '@/mixins/rbac-meta';
import ContactSupportModal from '@/components/ContactSupportModal';

export default {
    name: 'HelpMenu',
    components: { ContactSupportModal },
    mixins: [rbacMeta],
    data() {
        return {
            showModal: {
                contactSupport: false,
            },
            userManualLink: 'https://storage.googleapis.com/424595a2-4b7d-4840-8848-1d6d3e7a5451/assets/common/BNK_Webportal_Handbuch.pdf',
        };
    },
    computed: {},
    methods: {
        downloadDocumentation() {
            window.open(this.userManualLink, '_blank');
        },
    },
};
</script>

<style scoped lang="scss">
#help {
    padding-bottom: 0 !important;
}
</style>
