/**
 * @typedef ValidationError
 * @property {string} path
 * @property {string} message
 */

/**
 * Transforms a list of validation errors into a list of objects that match the expected format of the frontend
 * validation library "vee validate". For vee-validate, this is an object where each property corresponds to
 * a specific validated property path. Each property is an array of one or more validation error message strings.
 *
 * @link https://logaretm.github.io/vee-validate/advanced/server-side-validation.html
 *
 * @param {ValidationError[]} validationErrors
 * @return {Object.<string, string[]>}
 */
export default function veeValidateErrorTransformer(validationErrors) {
    let veeValidateErrors = {};
    for (const error of validationErrors) {
        const { path, message } = error;

        if (!veeValidateErrors[path]) {
            veeValidateErrors[path] = [];
        }
        veeValidateErrors[path].push(message);
    }

    return veeValidateErrors;
}
