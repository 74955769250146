import { Enum } from '@/helpers/choices/enum';

export class StateEnum extends Enum {}

StateEnum.values = {
    NO_STATE: 0,
    SUCCESS: 1,
    ERROR: 2,
};

StateEnum.names = {
    0: 'No State',
    1: 'Success',
    2: 'Error',
};

Object.freeze(StateEnum);
