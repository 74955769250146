<template>
    <v-dialog v-model="propDialog" persistent width="900px">
        <v-card>
            <v-card-title class="text-h5 lighten-2" primary-title>
                <v-icon small class="mr-2">$vuetify.icons.envelopeOpenText</v-icon>
                {{ $t('ui.contact_support') }}
            </v-card-title>
            <v-card-text>
                <v-col cols="12">
                    <ValidationObserver ref="form">
                        <form>
                            <v-col cols="12">
                                <ValidationProvider v-slot="{ errors }" vid="validationRoot">
                                    <v-alert v-if="errors && errors.length > 0" type="error" text>
                                        <span>{{ errors }}</span>
                                    </v-alert>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                                <v-select v-model="requestType" label="Request Type" name="requestType" :items="choices.requestType" />
                            </v-col>
                            <v-col cols="12">
                                <ValidationProvider v-slot="{ errors }" vid="emailText" name="Email Text" rules="required">
                                    <v-textarea v-model="emailText" :error-messages="errors" clearable filled />
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                                <v-btn class="group-button float-right" color="primary" :disabled="pendingSubmit" :loading="pendingSubmit" @click="submit"
                                    >Submit
                                </v-btn>
                                <v-btn color="secondary" class="group-button float-right" depressed text @click="cancel">Cancel </v-btn>
                            </v-col>
                        </form>
                    </ValidationObserver>
                </v-col>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { SupportEmailTypesEnum } from '@/helpers/choices/support-email-types-enum';
import { EmailContextEnum } from '@/helpers/choices/email-context-enum';

export default {
    name: 'ContactSupportModal',
    components: { ValidationObserver, ValidationProvider },
    props: {
        propDialog: { type: Boolean, default: false },
    },
    data() {
        return {
            pendingSubmit: false,
            choices: {
                requestType: SupportEmailTypesEnum.getFormChoices(),
            },
            requestType: SupportEmailTypesEnum.toFormChoice(SupportEmailTypesEnum.values.SUPPORT_REQUEST),
            emailText: '',
        };
    },
    methods: {
        async submit() {
            const isValid = await this.$refs.form.validate();
            if (!isValid) {
                return;
            }

            try {
                this.pendingSubmit = true;
                await this.$store.$axios.$post('/api/ext-services/emails/send', {
                    context: EmailContextEnum.values.SUPPORT,
                    metadata: {
                        fromEmail: this.$fire.auth.currentUser.email,
                        text: this.emailText,
                        type: parseInt(this.requestType.value),
                    },
                });
                await this.$store.dispatch('notify', {
                    message: this.$t('messages.contact_support_success'),
                    type: 'success',
                });
                // IMPORTANT reset current form so we can validate new inputs
                this.$refs.form.reset();
                this.$emit('close');
            } catch (e) {
                await this.$errorHandler.setForm(this.$refs.form).handle(e, { notify: true });
            }
            this.reset();
        },
        cancel() {
            this.reset();
            this.$emit('cancel');
            this.$refs.form.reset();
        },
        reset() {
            this.pendingSubmit = false;
            this.emailText = '';
            this.requestType = SupportEmailTypesEnum.toFormChoice(SupportEmailTypesEnum.values.SUPPORT_REQUEST);
        },
    },
};
</script>

<style scoped lang="scss"></style>
