<template>
    <v-navigation-drawer v-model="drawer" :rail="rail" :width="rail ? 60 : 256" app clipped disable-route-watcher :permanent="$vuetify.breakpoint.smAndUp">
        <v-list id="main-navigation" expand nav>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="authUser.hasPermission(permissions.OPERATING_WINDTURBINE_READ)"
                        :to="localePath('/monitoring')"
                        :nuxt="true"
                        :class="/^\/monitoring\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon small>$vuetify.icons.monitorWaveform</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $t('bnk_monitoring') }}</v-list-item-title>
                    </v-list-item>
                </template>
                <span>{{ $t('bnk_monitoring') }}</span>
            </v-tooltip>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="authUser.hasPermission(permissions.OPERATING_ATSINSTALLATION_READ)"
                        :to="localePath('/bnk-system')"
                        :nuxt="true"
                        :class="/^\/bnk-system\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon small>$vuetify.icons.signalStream</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $tc('bnk_system', 2) }}</v-list-item-title>
                    </v-list-item>
                </template>
                <span>{{ $tc('bnk_system', 2) }}</span>
            </v-tooltip>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="authUser.hasPermission(permissions.REPORTING_REPORT_CREATE)"
                        :to="localePath('/reporting')"
                        :nuxt="true"
                        :class="/^\/reporting\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon small>$vuetify.icons.fileChartColumn</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ capitalize($t('reporting')) }}</v-list-item-title>
                    </v-list-item>
                </template>
                <span>{{ capitalize($t('reporting')) }}</span>
            </v-tooltip>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="authUser.hasPermission([permissions.MONITORING_APP_SETTINGSREAD])"
                        :to="localePath('/admin/system')"
                        :nuxt="true"
                        :class="/^\/admin\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon small>$vuetify.icons.gears</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Administration</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Administration</span>
            </v-tooltip>
        </v-list>
        <template v-slot:append>
            <v-divider />
            <v-btn plain class="p-2 mb-10 float-right" @click="rail = !rail">
                <v-icon>{{ rail ? '$vuetify.icons.arrowRightFromLine' : '$vuetify.icons.arrowLeftFromLine' }} </v-icon>
            </v-btn>
        </template>
    </v-navigation-drawer>
</template>

<script>
import rbacMeta from '@/mixins/rbac-meta';
import capitalize from '@/mixins/capitalize';

export default {
    name: 'MainNavigation',
    mixins: [rbacMeta, capitalize],
    data() {
        return {
            rail: true,
            about: [
                { title: 'Legal', to: '/about/legal' },
                { title: 'Terms Of Use', to: '/about/terms' },
                { title: 'Privacy Policy', to: '/about/privacy-policy' },
            ],
        };
    },
    computed: {
        drawer: {
            // get value from store
            get() {
                return this.$store.state.mainNavigation;
            },
            // make sure to also "commit" changes to property into store => offset clicks when in overlay mode !!!
            set(val) {
                this.$store.commit('setMainNavigation', val);
            },
        },
    },
    mounted() {
        // suppress showing overlayed drawer on small screen sizes
        // and instead show full content and user must explicitly
        // click menu icon to open drawer
        if (!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.md) {
            this.$store.commit('setMainNavigation', true);
        }
    },
};
</script>
