import cleanDeep from 'clean-deep';
import checkTypes from 'check-types';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/globals';
import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    choices: {
        windPark: [],
        atsInstallation: [],
    },
    availableWindTurbines: new Map(),
    filterSearch: null,
    filterWindPark: null,
    filterAtsInstallation: null,
    name: null,
    includeBnkLogs: true,
    includePerformanceStatistics: true,
    includeMaintenanceActions: true,
    includeSeparateErrorLogs: true,
    windTurbines: [],
    periodDates: [],
});

export const getters = {
    getAvailableWindTurbines: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.availableWindTurbines.values());
    },
};

export const mutations = {
    clear(state) {
        state.availableWindTurbines = new Map();
        state.filterSearch = null;
        state.filterWindPark = null;
        state.filterAtsInstallation = null;
        state.name = null;
        state.includeBnkLogs = false;
        state.windTurbines = [];
        state.periodDates = [];
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setFilterSearch(state, value) {
        state.filterSearch = valueTransform.toString(value);
    },
    setFilterWindPark(state, value) {
        state.filterWindPark = valueTransform.toString(value);
    },
    setFilterAtsInstallation(state, value) {
        state.filterAtsInstallation = valueTransform.toString(value);
    },
    setName(state, value) {
        state.name = valueTransform.toString(value);
    },
    setIncludeBnkLogs(state, value) {
        state.includeBnkLogs = valueTransform.toBoolean(value);
    },
    setPeriodDates(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyString(value);
        state.periodDates = value;
    },
    setWindTurbines(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyObject(value);
        state.windTurbines = value;
    },
    setAvailableWindTurbines(state, value) {
        value = value || [];
        state.availableWindTurbines = new Map();
        value.forEach(function (item) {
            state.availableWindTurbines.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.availableWindTurbines = new Map(state.availableWindTurbines.entries());
    },
};

export const actions = {
    async loadAvailableWindTurbines({ commit, state }) {
        // append query parameters defined by selected filter values
        const { items } = await this.$axios.$get('/api/operating/wind-turbines', {
            params: {
                search: state.filterSearch,
                windPark: state.filterWindPark,
                atsInstallation: state.filterAtsInstallation,
                fields: [
                    '_id',
                    'name',
                    'project._id',
                    'project.projectId',
                    'operatorIdent',
                    'windPark._id',
                    'windPark.name',
                    'atsInstallation._id',
                    'atsInstallation.name',
                ].join(','),
                populateRefs: true,
            },
        });

        commit('setAvailableWindTurbines', items);
    },
    async generateReport({ state }) {
        // get a list of wind turbine IDs
        const windTurbineIds = state.windTurbines.map((value) => value._id);

        // extract start and end dates => may also be a single date
        // convert simple string, e.g. "2020-01-11", that is ALWAYS assuemd to be in UTC
        let date0;
        let date1;
        if (state.periodDates.length === 2) {
            date0 = DateTime.fromFormat(state.periodDates[0], DATE_FORMAT, { zone: 'UTC' });
            date1 = DateTime.fromFormat(state.periodDates[1], DATE_FORMAT, { zone: 'UTC' });
        } else {
            date0 = DateTime.fromFormat(state.periodDates[0], DATE_FORMAT, { zone: 'UTC' });
            date1 = date0;
        }
        // make sure that start date is before end date => period dates does not necessarily have "ordered" values
        const startDate = date0 < date1 ? date0 : date1;
        const endDate = date0 > date1 ? date0 : date1;

        let denormalized = {
            name: state.name,
            windTurbines: windTurbineIds,
            bnkLogs: state.includeBnkLogs,
            bnkPerformanceStats: true,
            maintenanceActions: true,
            separateErrorLogs: true,
            startDate: startDate.toFormat(DATE_FORMAT),
            endDate: endDate.toFormat(DATE_FORMAT),
        };

        await this.$axios.$post('/api/reporting/reports', cleanDeep(denormalized));
    },
};
