import checkTypes from 'check-types';

/**
 * Base enum class that provides common logic as static methods.
 * Names and values must be defined in concrete classes later.
 */
export class Enum {
    /**
     * Returns the currently defined enum values/names pairs as form
     * choices.
     *
     * @param {Object} config
     * @return {[]}
     */
    static getFormChoices(config = {}) {
        const { itemText = 'text', sort, filter } = config;
        let sortFunc =
            sort ||
            function (a, b) {
                if (a[itemText] < b[itemText]) {
                    return -1;
                }
                if (b[itemText] < a[itemText]) {
                    return 1;
                }
                return 0;
            };
        if (sort === false) {
            sortFunc = function (a, b) {
                return 0;
            };
        }

        const filterStrings = filter?.map((value) => value.toString());
        const choices = [];
        Object.entries(this.names).forEach(([value]) => {
            // omit each enum value that is NOT in the filter list
            if (filterStrings?.length > 0 && filterStrings.includes(value) === false) {
                // do nothing
            } else {
                // include only enum values specified within the filter list
                // noinspection JSCheckFunctionSignatures
                choices.push(this.toFormChoice(value, config));
            }
        });

        choices.sort(sortFunc);

        return choices;
    }

    /**
     * Returns a form choice object literal from an input enum int value.
     *
     * @param {integer} value
     * @param {Object|null} config
     * @return {{text: string, value: number}}
     */
    static toFormChoice(value, config = {}) {
        const { itemText = 'text', itemValue = 'value' } = config;

        let choice = {};

        choice[itemText] = this.names[value];
        // IMPORTANT type cast "value" to string
        choice[itemValue] = value.toString();

        return choice;
    }

    /**
     * Converts either a list of enum int values into either a
     * list of form choices object literals.
     *
     * @param {array} values
     * @param {Object|null} config
     * @return {{text: string, value: number}[]}
     */
    static toFormChoices(values, config = {}) {
        // convert null into empty array
        let choicesValues = values ?? [];
        // only operate on list of values
        choicesValues = Array.isArray(choicesValues) ? choicesValues : [choicesValues];
        let choices = [];
        for (const v of choicesValues) {
            choices.push(Enum.toFormChoice(v, config));
        }

        return choices;
    }

    /**
     * @param {integer|null} value
     * @returns {string|null}
     */
    static fromEnumValue(value) {
        if (value == null) return null;

        return value.toString();
    }

    /**
     * @param {array|null} value
     * @returns {array|null}
     */
    static fromEnumList(value) {
        if (value == null) return null;
        checkTypes.assert.array.of.integer(value);

        return value.map((value) => value.toString());
    }

    /**
     * Returns a concatenated string of enum names.
     *
     * @param {string|string[]|int|int[]} values
     * @param {string} separator
     * @return {string|null}
     */
    static asConcatNames(values, separator = ', ') {
        // explicitly return null to have a null value denormalized
        if (!values) return null;

        // only operate on list of values
        const enumValues = Array.isArray(values) ? values : [values];

        let names = [];
        for (const v of enumValues) {
            names.push(this.getName(v));
        }

        return names.join(separator);
    }

    /**
     * @param {integer} value
     * @returns {string|null}
     */
    static getName(value) {
        return this.names[value];
    }

    /**
     * @param {integer[]} values
     * @returns {string[]|[]}
     */
    static getNames(values) {
        if (values == null) return [];

        const list = [];
        for (const value of values) {
            list.push(this.getName(value));
        }

        return list;
    }

    /**
     * Returns the enum value for a given "name". Note that string comparison is
     * case-sensitive by default.
     *
     * @param {string} name
     * @param {boolean} [strict] - Defaults to true. If true, string comparison is case-sensitive.
     * @return {integer|string|null}
     */
    static getValue(name, strict = true) {
        let list = Object.assign({}, this.names);
        if (!strict) {
            name = name.toLowerCase();
            Object.keys(list).map(function (key, index) {
                list[key] = list[key].toLowerCase();
            });
        }
        let enumValue = Object.keys(list).find((key) => list[key] === name);
        enumValue = isNaN(enumValue) ? enumValue : parseInt(enumValue);

        return enumValue == null ? null : enumValue;
    }

    /**
     * @param {number|string} value
     * @returns string
     */
    static getConstName(value) {
        let name = '';
        for (const constName in this.values) {
            if (this.values[constName] === parseInt(value)) name = constName;
        }

        return name;
    }
}

Enum.names = {};
Enum.values = {};
