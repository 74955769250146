import { QUERY_PARAM_ORIGIN_URL } from '@/globals';
import * as queryString from 'query-string';

class Backpedal {
    constructor({ store, router }) {
        // IMPORTANT Do not inject and use route here => will be cached and re-used which will lead to very obscure behavior.
        //  Instead pass route as "currentRoute" func parameter in "createPushBackPath"
        this.store = store;
        this.router = router;
    }

    hasOriginUrl() {
        return this.store.state.originUrl != null;
    }

    createPushBackPath({ path, currentRoute }) {
        if (path == null) return null;

        //check if path has query parameters
        const pathHasQueryParams = path.indexOf('?') !== -1;
        // extract the target "base" path without query parameters
        const pathBase = pathHasQueryParams ? path.substring(0, path.indexOf('?')) : path;
        // extract query params appended to the target path
        let pathQueryString = null;
        if (pathHasQueryParams) {
            pathQueryString = queryString.parse(path.substring(path.indexOf('?'), path.length));
        }

        // IMPORTANT remove and overwrite previous "originUrl" query parameter on each navigational step
        const currentQuery = currentRoute.query;
        delete currentQuery[QUERY_PARAM_ORIGIN_URL];
        // extract all other query parameters from the current route
        const currentQueryParams = [];
        for (const [key, value] of Object.entries(currentQuery)) {
            currentQueryParams.push(`${key}=${encodeURIComponent(value)}`);
        }

        /*
        Build the pushBack path which will contain the given target path with query parameters and an additional
        query parameter "originUrl" which contains the current path with all query parameters.
         */
        let backpedalPathQueryParams = '';
        // re-add target path query parameters
        if (pathHasQueryParams) {
            backpedalPathQueryParams += `?${queryString.stringify(pathQueryString)}`;
        }
        // add the "originUrl" query parameter which is later used to "push back" user to this exact same url
        // so that eventually set list filters etc. will be active again
        if (pathHasQueryParams) {
            backpedalPathQueryParams += '&';
        } else {
            // if target path has no own query parameters, start the query params section
            backpedalPathQueryParams += '?';
        }

        // add "originUrl" query parameter
        backpedalPathQueryParams +=
            QUERY_PARAM_ORIGIN_URL +
            '=' +
            encodeURIComponent(`${currentRoute.path}${currentQueryParams.length === 0 ? '' : '?' + currentQueryParams.join('&')}`);

        return pathBase + backpedalPathQueryParams;
    }

    async pushBack(fallbackUrl) {
        await this.router.push(this.getPushBackUrl(fallbackUrl));
    }

    getPushBackUrl(fallbackUrl) {
        // if nothing is defined, push to home
        let pushUrl = '/';
        if (this.store.state.originUrl != null) {
            // use originUrl found in equally named query parameter
            pushUrl = this.store.state.originUrl;
        } else if (fallbackUrl != null) {
            // use fallback if defined and originUrl query parameters is not set
            pushUrl = fallbackUrl;
        }

        return pushUrl;
    }
}

export default ({ store, route }, inject) => {
    const { $router: router } = store;

    inject('backpedal', new Backpedal({ store, route, router }));
};
