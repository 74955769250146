import checkTypes from 'check-types';
import { PERSIST_KEY } from '@/globals';
import DevLogger from '@/helpers/dev-logger';

/**
 * @typedef UserPrefsMetadata
 *
 * @property {Object[]} [notifications]
 * @property {boolean} [darkMode]
 */

/**
 * Represents the current logged in user's app configuration settings. The UserPrefs's
 * state is constantly persisted to the local storage to be persistent throughout
 * page reloads and sessions.
 */
export default class UserPrefs {
    /**
     * @param {UserPrefsMetadata} userPrefsMetadata
     */
    constructor(userPrefsMetadata) {
        const { notifications, darkMode } = userPrefsMetadata || {};

        if (notifications) checkTypes.assert.array.of.string(notifications);
        if (darkMode) checkTypes.assert.boolean(darkMode);

        this._storageKey = `${PERSIST_KEY}.userPrefs`;
        // set empty list of notifications as default
        this.notifications = notifications || [];
        // default is to use light theme
        this.darkMode = darkMode || false;
    }

    toJSON() {
        return {
            notifications: this.notifications,
            darkMode: this.darkMode,
        };
    }

    persistToLocalStorage() {
        if (!process.browser) {
            console.error('Cannot persist app user on server side');
            return;
        }
        const storage = window.localStorage;
        storage.setItem(this._storageKey, JSON.stringify(this.toJSON()));
    }

    static loadFromLocalStorage() {
        if (!process.browser) {
            console.error('Cannot persist app user on server side');
            return;
        }

        const storage = window.localStorage;
        const metadata = storage.getItem(`${PERSIST_KEY}.userPrefs`);

        if (!metadata) {
            DevLogger.log('Cannot load app user from localStorage');

            return null;
        }

        return new UserPrefs(JSON.parse(metadata));
    }

    deleteFromLocalStorage() {
        if (!process.browser) {
            console.error('Cannot delete app user on server side');
            return;
        }

        const storage = window.localStorage;
        storage.removeItem(this._storageKey);
    }

    /**
     * @param {string | string[]} notifications
     */
    addNotifications(notifications) {
        this._enforceNotificationsType(notifications);

        const ntfctn = Array.isArray(notifications) ? notifications : [notifications];

        for (const item of ntfctn) {
            if (!this.notifications.includes(item)) {
                this.notifications.push(item);
            }
        }
    }

    /**
     * @param {string | string[]} notifications
     */
    removeNotifications(notifications) {
        this._enforceNotificationsType(notifications);

        const ntfctn = Array.isArray(notifications) ? notifications : [notifications];

        this.notifications = this.notifications.map((value) => {
            if (!ntfctn.includes(value)) return value;
        });
    }

    clearNotifications() {
        this.notifications = [];
    }

    /**
     * @param {*} notifications
     * @private
     */
    _enforceNotificationsType(notifications) {
        if (!checkTypes.string(notifications) && !checkTypes.array.of.string(notifications)) {
            throw new TypeError('Argument must either be a string or an array of strings');
        }
    }
}
