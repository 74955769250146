import { deferNotify } from '@/helpers/defer-notify';
import DevLogger from '@/helpers/dev-logger';

let _dispatch;

export function deferNotifyHandler(dispatch) {
    _dispatch = dispatch;

    return async function (route) {
        if (route?.query?.deferNotify) {
            const notification = deferNotify[route.query.deferNotify];
            if (notification) {
                await dispatch('notify', {
                    message: notification.message,
                    type: notification.type,
                });
            } else {
                DevLogger.warn(`Defer notify metadata ${route.query.deferNotify} not defined`);
            }
        }
    };
}
