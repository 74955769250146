<template>
    <v-app>
        <v-main>
            <nuxt />
        </v-main>
        <scroll-to-top-button />
        <page-footer />
    </v-app>
</template>

<script>
import PageFooter from '@/components/PageFooter';
import ScrollToTopButton from '@/components/ScrollToTopButton';

// IMPORTANT stand-alone layout since also publicly accessible
export default {
    name: 'Legal',
    components: {
        ScrollToTopButton,
        PageFooter,
    },
};
</script>

<style lang="scss">
footer {
    margin-top: 20em;
}
</style>
