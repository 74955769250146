import checkTypes from 'check-types';

export const state = () => ({
    serviceAccounts: [],
});

export const mutations = {
    clear(state) {
        state.serviceAccounts = [];
    },
    setServiceAccounts(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyObject(value);
        state.serviceAccounts = value;
    },
};

export const actions = {
    async loadServiceAccounts({ commit }) {
        const accounts = await this.$axios.$get('/api/iam/users/profile/service-accounts');
        commit('setServiceAccounts', accounts);
    },
};
