import checkTypes from 'check-types';
import getWindTurbineName from '@/helpers/get-wind-turbine-name';

export const state = () => ({
    windPark: {},
    windTurbines: [],
});

export const mutations = {
    clear(state) {
        state.windPark = {};
        state.windTurbines = [];
    },
    setWindPark(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.windPark = value;
    },
    setWindTurbines(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyObject(value);
        state.windTurbines = value;
    },
};

export const actions = {
    async loadMetadata({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const windPark = await this.$axios.$get(`/api/operating/wind-parks/${id}`, {
            params: { fields: '_id,name' },
        });
        // load wind park wind turbines
        const { items: windTurbines } = await this.$axios.$get('/api/operating/wind-turbines', {
            params: {
                fields: ['_id', 'manufacturer', 'serialNumber', 'operatorIdent', 'type', 'windPark._id', 'windPark.name', 'geometry'].join(','),
                windPark: id,
                populateRefs: true,
            },
        });

        // inject a custom wind turbine name for viewing purposes
        windTurbines.forEach((value) => {
            value.name = getWindTurbineName({
                id: value._id,
                manufacturer: value.manufacturer,
                serialNumber: value.serialNumber,
                operatorIdent: value.operatorIdent,
                type: value.type,
            });
        });

        commit('setWindPark', windPark);
        commit('setWindTurbines', windTurbines);
    },
};
