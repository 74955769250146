import checkTypes from 'check-types';

export const state = () => ({
    linkedTurbines: new Map(),
});

export const getters = {
    getLinkedTurbines: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.linkedTurbines.values());
    },
};

export const mutations = {
    clear(state) {
        state.linkedTurbines = new Map();
        state.loading = false;
    },
    setLinkedTurbines(state, value) {
        value = value || [];
        state.linkedTurbines = new Map();
        value.forEach(function (item) {
            state.linkedTurbines.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.linkedTurbines = new Map(state.linkedTurbines.entries());
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        // append query parameters defined by selected filter values
        const { items } = await this.$axios.$get('/api/operating/wind-turbines', {
            params: {
                atsInstallation: id,
                fields: ['manufacturer', 'windPark.name', 'type', 'serialNumber', 'operatorIdent'].join(','),
                populateRefs: true,
            },
        });
        commit('setLinkedTurbines', items);
    },
};
