<template>
    <v-col v-if="maintenanceMode" cols="12">
        <v-alert type="warning" text>
            Site is currently in maintenance mode. Only privileged users are allowed to access.
            <template v-slot:prepend>
                <v-icon small color="warning" class="mr-4">$vuetify.icons.wrench</v-icon>
            </template>
        </v-alert>
    </v-col>
</template>

<script>
export default {
    name: 'MaintenanceModeAlert',
    computed: {
        maintenanceMode() {
            return this.$store.state.maintenanceMode;
        },
    },
};
</script>
