import checkTypes from 'check-types';

export const state = () => ({
    windTurbine: {},
});

export const mutations = {
    clear(state) {
        state.windTurbine = {};
    },
    setWindTurbine(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.windTurbine = value;
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const windTurbine = await this.$axios.$get(`/api/operating/wind-turbines/${id}`, {
            params: {
                fields: [
                    '_id',
                    'country',
                    'manufacturer',
                    'serialNumber',
                    'type',
                    'readOnly',
                    'commissioning',
                    'operatorIdent',
                    'project',
                    'windPark',
                    'geometry',
                    'elevation',
                    'ctrlAtsInstallation._id',
                    'ctrlAtsInstallation.name',
                    'ctrlAtsInstallation.ats.serialNumber',
                    'ctrlAtsInstallation.geometry',
                    'sensorAtsInstallation._id',
                    'sensorAtsInstallation.name',
                    'sensorAtsInstallation.ats.serialNumber',
                    'sensorAtsInstallation.geometry',
                    'hubHeight',
                    'rotorLength',
                    'configuration',
                    'lightingSystemLatency',
                    'intersectsNlfs',
                    'responsibilities.responsibility',
                    'responsibilities.businessEntity',
                    'extendedConfig',
                    'createdAt',
                    'updatedAt',
                ].join(','),
                populateRefs: true,
            },
        });

        commit('setWindTurbine', windTurbine);
    },
};
