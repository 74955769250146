import { isEmpty } from 'lodash';
import checkTypes from 'check-types';
import { MESSAGE_ERROR_APPLICATION, MESSAGE_ERROR_SERVICE_UNAVAILABLE, MESSAGE_INVALID_PAYLOAD } from '@/globals';
import DevLogger from '@/helpers/dev-logger';
import veeValidateErrorTransformer from '@/helpers/vee-validate-error-transformer';
import StackdriverErrorReporter from '@/helpers/stackdriver-errors';

export default class ErrorHandler {
    constructor(config) {
        const { dispatch, error, axios, apiKey, projectId, serviceName, serviceRevision, serviceType, projectGitUri, projectGitCommit } = config;

        checkTypes.assert.function(dispatch);
        checkTypes.assert.function(error);
        checkTypes.assert.function(axios);
        checkTypes.assert.string(apiKey);
        checkTypes.assert.string(serviceName);
        checkTypes.assert.string(serviceType);
        checkTypes.assert.string(projectId);
        checkTypes.assert.string(projectGitUri);
        checkTypes.assert.string(projectGitCommit);

        this.dispatch = dispatch;
        this.error = error;
        this.axios = axios;
        this.form = null;
        this.userId = null;
        this.defaultMessage = MESSAGE_ERROR_APPLICATION;
        this.projectGitUri = projectGitUri;
        this.projectGitCommit = projectGitCommit;
        // stackdriver error reporter
        this.stackdriverErrorReporter = StackdriverErrorReporter.init({
            apiKey,
            projectId,
            service: serviceName,
            version: serviceRevision.toString(),
            repository: projectGitUri,
            revisionId: projectGitCommit,
        });
    }

    setForm(form) {
        this.form = form;

        return this;
    }

    setUser(userId) {
        this.userId = userId;

        return this;
    }

    setDefaultMessage(message) {
        this.defaultMessage = message;

        return this;
    }

    async handle(e, options) {
        let { notify, redirectErrors } = Object.assign({ notify: false, redirectErrors: false }, options);

        let message = this.defaultMessage;
        let notifyType;

        // backend errors that provide more information
        if (e.response) {
            const res = e.response;
            // error without response instance are application errors and use default values
            const statusCode = res?.status;
            const statusText = res?.statusText;

            // redirect error to error page if set
            if (redirectErrors) return this.error({ statusCode, statusText });

            // handle 400
            switch (statusCode) {
                case 400:
                    notifyType = 'error';
                    // check if we have a custom error message in res.data
                    if (checkTypes.nonEmptyString(res.data)) {
                        message = res.data;
                    } else if (checkTypes.nonEmptyString(res?.data?.message)) {
                        message = res.data.message;
                    } else {
                        message = res.message ? res.message : 'Bad request';
                    }
                    break;
                case 422:
                    notifyType = 'warning';
                    message = MESSAGE_INVALID_PAYLOAD;

                    if (this.form && res.data && res.data.errors) {
                        if (isEmpty(res.data.errors)) {
                            // use response data message as fallback if no errors are set
                            this.form.setErrors({ validationRoot: res.data.message });
                        } else {
                            // transform errors into representation that frontend validation library vee-validate accepts
                            const veeValidateErrors = veeValidateErrorTransformer(res.data.errors);
                            this.form.setErrors(veeValidateErrors);
                        }
                    }
                    break;
                case 409:
                    notifyType = 'warning';
                    // check if we have a custom error message in res.data
                    if (checkTypes.nonEmptyString(res.data)) {
                        message = res.data;
                    } else if (checkTypes.nonEmptyString(res?.data?.message)) {
                        message = res.data.message;
                    } else {
                        message = res.message ? res.message : 'Conflict';
                    }
                    break;
                case 403:
                case 404:
                    return;
                case 504:
                    notifyType = 'warning';
                    message = MESSAGE_ERROR_SERVICE_UNAVAILABLE;
                    break;
                default:
                    notifyType = 'error';
            }
        }

        DevLogger.error(e);

        if (process.env.NODE_ENV !== 'development') {
            try {
                this.stackdriverErrorReporter.setUser(this.userId);
                await this.stackdriverErrorReporter.report(e);
            } catch (e) {
                // suppress error message on failed reports
                DevLogger.warn(e);
            }
        }

        if (notify) {
            await this.dispatch('notify', {
                message: message,
                type: notifyType,
            });
        }
    }
}
