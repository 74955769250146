<template>
    <v-app-bar app dense dark class="navbar" clipped-left>
        <v-app-bar-nav-icon class="mr-3" @click="openSidebar()" />
        <nuxt-link v-show="$vuetify.breakpoint.smAndUp" :to="localePath('/')">
            <img :src="`${$config.COMMON_WEB_ASSETS_URL}/logo.png?hl=en`" alt="site-logo" height="30" title="Back to home" />
        </nuxt-link>
        <v-spacer />
        <app-switcher />
        <client-only>
            <lang-switcher />
            <notification-list v-if="authUser.hasPermission([permissions.NOTIFICATION_NOTIFICATION_READ])" />
            <help-menu />
            <user-menu />
        </client-only>
    </v-app-bar>
</template>

<script>
import UserMenu from '@/components/UserMenu';
import NotificationList from '@/components/NotificationList';
import LangSwitcher from '@/components/LangSwitcher';
import rbacMeta from '@/mixins/rbac-meta';
import AppSwitcher from '@/components/AppSwitcher';
import HelpMenu from '@/components/HelpMenu';

export default {
    name: 'Navbar',
    components: { HelpMenu, LangSwitcher, AppSwitcher, NotificationList, UserMenu },
    mixins: [rbacMeta],
    props: {
        propHideToggle: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        openSidebar() {
            this.$store.commit('setMainNavigation', true);
        },
    },
};
</script>

<style scoped lang="scss">
#navbar-portal-title {
    font-size: 1.2em !important;
    margin-left: 1em !important;
}
</style>
