import { Enum } from '@/helpers/choices/enum';

export class OperationModeEnum extends Enum {}

OperationModeEnum.values = {
    NORMAL: 0,
    TEST: 1,
    INOPERATIVE: 2,
    MAINTENANCE: 3,
};

OperationModeEnum.names = {
    0: 'Normal',
    1: 'Test',
    2: 'Inoperative',
    3: 'Maintenance',
};

Object.freeze(OperationModeEnum);
