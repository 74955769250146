import { Enum } from '@/helpers/choices/enum';

export class SupportEmailTypesEnum extends Enum {}

SupportEmailTypesEnum.values = {
    SUPPORT_REQUEST: 0,
    BUG_REPORT: 1,
    FEATURE_REQUEST: 2,
};

SupportEmailTypesEnum.names = {
    0: 'Support Request',
    1: 'Bug Report',
    2: 'Feature Request',
};

Object.freeze(SupportEmailTypesEnum);
