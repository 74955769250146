import { Enum } from '@/helpers/choices/enum';

export class ErrorSeverityEnum extends Enum {}

/*
 Current simple mapping of error severities. In contrast to other enum objects, the enum
 integer values simply mark a threshold at which and above the error is considered
 to be this type or error. Can be adjusted dynamically when new error severity ranges
 are defined.
 */

ErrorSeverityEnum.values = {
    INFO: 0,
    WARNING: 1,
    MINOR: 2,
    MAJOR: 3,
    CRITICAL: 4,
    UNKNOWN_CRITICALITY: 5,
};

ErrorSeverityEnum.names = {
    0: 'Information',
    1: 'Warning',
    2: 'Minor Error',
    3: 'Major Error',
    4: 'Critical Error',
    5: 'Unknown Criticality',
};

Object.freeze(ErrorSeverityEnum);
