import cleanDeep from 'clean-deep';
import checkTypes from 'check-types';
import { LIST_ITEMS_PER_PAGE_DEFAULT } from '@/globals';
import * as valueTransform from '@/helpers/value-transform';
import { ErrorSeverityEnum } from '@/helpers/choices/error-severity-enum';
import { OperationModeEnum } from '@/helpers/choices/ats-installation/operation-mode-enum';
import { getFormChoices as countryChoices } from '@/helpers/choices/countries';

const choices = {
    severity: ErrorSeverityEnum.getFormChoices({ sort: false }),
    operationMode: OperationModeEnum.getFormChoices(),
    country: countryChoices(),
};

export const state = () => ({
    choices,
    items: new Map(),
    page: 1,
    totalItems: 0,
    limit: LIST_ITEMS_PER_PAGE_DEFAULT,
    sortBy: null,
    sortDesc: null,
    filterSearch: null,
    filterSearchOptLwc: false,
    filterWindPark: null,
    filterAtsInstallation: null,
    filterBusinessEntity: null,
    filterSeverity: null,
    filterOperationMode: null,
    filterCountry: null,
    filterIsAlive: null,
});

export const getters = {
    getItems: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.items.values());
    },
    getQueryFilters: (state) => {
        return {
            page: state.page,
            limit: state.limit,
            sortBy: state.sortBy,
            sortDesc: state.sortDesc,
            search: state.filterSearch,
            searchOptLwc: state.filterSearchOptLwc,
            windPark: state.filterWindPark,
            atsInstallation: state.filterAtsInstallation,
            businessEntity: state.filterBusinessEntity,
            severity: state.filterSeverity,
            operationMode: state.filterOperationMode,
            country: state.filterCountry,
            isAlive: state.filterIsAlive,
        };
    },
    hasSpecialFilters: (state) => {
        return (
            Object.values(
                cleanDeep({
                    windPark: state.filterWindPark,
                    atsInstallation: state.filterAtsInstallation,
                    businessEntity: state.filterBusinessEntity,
                    severity: state.filterSeverity,
                    operationMode: state.filterOperationMode,
                    country: state.filterCountry,
                    isAlive: state.filterIsAlive,
                })
            ).length > 0
        );
    },
};

export const mutations = {
    clearSpecialFilters(state) {
        state.filterWindPark = null;
        state.filterAtsInstallation = null;
        state.filterBusinessEntity = null;
        state.filterSeverity = null;
        state.filterOperationMode = null;
        state.filterCountry = null;
        state.filterIsAlive = null;
    },
    clear(state) {
        state.choices = choices;
        state.status = new Map();
        state.items = new Map();
        state.page = 1;
        state.limit = LIST_ITEMS_PER_PAGE_DEFAULT;
        state.totalItems = 0;
        state.sortBy = null;
        state.sortDesc = null;
        state.filterSearch = null;
        state.filterSearchOptLwc = null;
        state.filterWindPark = null;
        state.filterAtsInstallation = null;
        state.filterBusinessEntity = null;
        state.filterSeverity = null;
        state.filterOperationMode = null;
        state.filterCountry = null;
        state.filterIsAlive = null;
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setPage(state, value) {
        state.page = valueTransform.toInteger(value, { nullable: false });
    },
    setLimit(state, value) {
        state.limit = valueTransform.toInteger(value, { nullable: false });
    },
    setSortBy(state, value) {
        state.sortBy = valueTransform.toString(value);
    },
    setSortDesc(state, value) {
        state.sortDesc = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterSearch(state, value) {
        state.filterSearch = valueTransform.toString(value);
    },
    setFilterSearchOptLwc(state, value) {
        state.filterSearchOptLwc = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterWindPark(state, value) {
        state.filterWindPark = valueTransform.toStringList(value);
    },
    setFilterAtsInstallation(state, value) {
        state.filterAtsInstallation = valueTransform.toStringList(value);
    },
    setFilterBusinessEntity(state, value) {
        state.filterBusinessEntity = valueTransform.toStringList(value);
    },
    setFilterSeverity(state, value) {
        state.filterSeverity = valueTransform.toInteger(value);
    },
    setFilterOperationMode(state, value) {
        state.filterOperationMode = valueTransform.toIntegerList(value, { nullable: true });
    },
    setFilterCountry(state, value) {
        state.filterCountry = valueTransform.toStringList(value, { nullable: true });
    },
    setFilterIsAlive(state, value) {
        state.filterIsAlive = valueTransform.toBoolean(value, { nullable: true });
    },
    setTotalItems(state, value) {
        state.totalItems = valueTransform.toInteger(value);
    },
    setItems(state, value) {
        value = value || [];
        state.items = new Map();
        value.forEach(function (item) {
            state.items.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    setStatus(state, value) {
        value = value || [];
        state.status = new Map();
        value.forEach(function (item) {
            state.status.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.status = new Map(state.status.entries());
    },
};

export const actions = {
    async loadStatus({ commit, state }) {
        const { items, totalCount } = await this.$axios.$get('/api/monitoring/status', {
            params: cleanDeep({
                page: state.page,
                limit: state.limit,
                sortBy: state.sortBy,
                sortDesc: state.sortDesc,
                search: state.filterSearch,
                searchOptLwc: state.filterSearchOptLwc,
                country: state.filterCountry,
                severity: state.filterSeverity,
                operationMode: state.filterOperationMode,
                windPark: state.filterWindPark,
                atsInstallation: state.filterAtsInstallation,
                businessEntity: state.filterBusinessEntity,
                isAlive: state.filterIsAlive,
            }),
        });
        commit('setItems', items);
        commit('setTotalItems', totalCount);
    },
};
