import { secondsToHHMMSS } from '@/helpers/seconds-to-hhmmss';

/**
 * @param {object} stats - wind turbine statistics
 * @return {{}}
 */
export default async function prepareTurbineStats(stats) {
    if (stats == null) return;

    const { secNight, secSystemOk, secLightsOn, secAutoLightsOn, secManualLightsOn } = stats;

    let durationNighttime = secondsToHHMMSS(secNight) ?? undefined;
    let durationSystemOk = secondsToHHMMSS(secSystemOk) ?? undefined;
    let durationLightsOn = secondsToHHMMSS(secLightsOn) ?? undefined;
    let durationAutoLightsOn = secondsToHHMMSS(secAutoLightsOn) ?? undefined;
    let durationManualLightsOn = secondsToHHMMSS(secManualLightsOn) ?? undefined;
    const secFailSafeLightsOn = secLightsOn - secAutoLightsOn - secManualLightsOn;
    let durationFailSafeLightsOn = secondsToHHMMSS(secFailSafeLightsOn) ?? undefined;

    // convert to human-readable strings where applicable
    stats.humanRead = {
        durationNighttime,
        durationSystemOk,
        durationLightsOn,
        durationAutoLightsOn,
        durationManualLightsOn,
        durationFailSafeLightsOn,
    };
}
