import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import { APP_CONTEXT } from '@/globals';
import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    id: null,
    message: null,
    expireAt: null,
});

export const mutations = {
    clear(state) {
        state.id = null;
        state.message = null;
        state.expireAt = null;
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setMessage(state, value) {
        state.message = valueTransform.toString(value);
    },
    setExpireAt(state, value) {
        state.expireAt = valueTransform.toString(value);
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const { _id, message } = await this.$axios.$get(`/api/messaging/messages/${id}`);

        commit('setI', _id);
        commit('setMessage', message);
    },
    async new({ state }) {
        const { _id } = await this.$axios.$post(
            '/api/messaging/messages',
            cleanDeep({ message: state.message, context: APP_CONTEXT, expiredAt: state.expireAt })
        );

        // return the id of the new resource so the app can directly redirect the user to the edit
        // page to start editing fields that are only available after a resource has been created
        return _id;
    },
};
