import { upperFirst } from 'lodash';

/**
 * @param {string} string
 * @return {string}
 */
export default function capitalizeWords(string) {
    let splits = string.split(' ');
    splits = splits.map((value) => value.toString().trim());
    splits = splits.map((value) => upperFirst(value));

    return splits.join(' ');
}
