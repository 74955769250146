/**
 * Converts a seconds integer value into a duration string
 * in format "HH:MM:SS".
 *
 * @param {number|string} seconds
 * @return {string}
 */
export function secondsToHHMMSS(seconds) {
    let sec_num = parseInt(String(seconds), 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let secs = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
        // @ts-ignore
        hours = '0' + hours;
    }
    if (minutes < 10) {
        // @ts-ignore
        minutes = '0' + minutes;
    }
    if (secs < 10) {
        // @ts-ignore
        secs = '0' + secs;
    }
    return hours + ':' + minutes + ':' + secs;
}
