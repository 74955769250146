import ErrorHandler from '@/helpers/error-handler';

export default async ({ error, app, $fire }, inject) => {
    const dispatch = app.store.dispatch;
    const axios = app.store.$axios;
    const apiKey = app.$config.GCP_ERROR_REPORTING_API_KEY;
    const projectId = app.$config.GCP_PROJECT_ID;
    const serviceName = app.$config.GCP_SERVICE_NAME;
    const serviceRevision = app.$config.GCP_SERVICE_REVISION;
    const projectGitUri = app.$config.GCP_PROJECT_GIT_URI;
    const projectGitCommit = app.$config.GCP_PROJECT_GIT_COMMIT;

    const errorHandler = new ErrorHandler({
        dispatch,
        error,
        axios,
        apiKey,
        projectId,
        serviceName,
        serviceType: 'cloud_run_revision',
        serviceRevision,
        projectGitUri,
        projectGitCommit,
    });

    const auth = await $fire.auth;
    if (auth?.currentUser) {
        errorHandler.setUser(auth.currentUser.uid);
    }

    inject('errorHandler', errorHandler);
};
